import * as React from 'react';
import { styled } from '@mui/material/styles';
import logo from '../../../src/assets/logo.png';
import {
    AppBar,
    Box,
    Toolbar,
    IconButton,
    Typography,
    InputBase,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Divider,
    Button,
    Link
} from '@mui/material';

// Icons
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import shoppingBag from '../../../src/assets/icons/ic_shoppingBag.svg';
import userBorder from '../../../src/assets/icons/ic_userBorder.svg';
import searchIcon from '../../../src/assets/icons/ic_search_ecommerce.svg';
import { useNavigate } from 'react-router-dom';
import Iconify from '../../../src/components/Iconify';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from '../../redux/store';
// intl
import { FormattedMessage, useIntl } from 'react-intl';



interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
}

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#F1F1F1',
    '&:hover': {
        backgroundColor: '#e8e8e8',
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '362px',
    height: '44px'
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: 'auto'
    },
}));
const drawerWidth = 240;


export default function NavBar(props: Props) {
    const intl = useIntl();
    const { window } = props;

    const navItems = [
        intl.formatMessage({ id: 'home.texto-1', defaultMessage: 'Women' }),
        intl.formatMessage({ id: 'home.texto-2', defaultMessage: 'Men' }),
        intl.formatMessage({ id: 'home.texto-3', defaultMessage: 'Accessories' }),
        intl.formatMessage({ id: 'home.texto-15', defaultMessage: 'SALE' })
    ];

    const navigate = useNavigate();
    const { cart, environment } = useSelector((state) => state.nuDetectState);

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const container = window !== undefined ? () => window().document.body : undefined;

    const menuId = 'primary-search-account-menu';

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const showCatalog = (catalogName: any) => {
        navigate('/catalog', { state: catalogName });
    }

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                Menu
            </Typography>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item} disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText primary={item} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar className='navbar' position="static" sx={{ paddingLeft: '15px', color: 'black', backgroundColor: 'white', minHeight: 80, justifyContent: 'center', boxShadow: 'none' }}>
                <Toolbar sx={{ paddingLeft: '10px !important', paddingRight: '10px !important' }}>
                    <Link
                        component={RouterLink}
                        to='/'>
                        <IconButton>
                            <img src={logo} alt="overlap" width="104px" />
                        </IconButton>
                        <Divider sx={{margin: 0, height: 5, backgroundColor: environment.color, border: 'none'}}></Divider>
                    </Link>
                    <Box sx={{ display: { xs: 'none', sm: 'flex' }, ml: 2.5, gap: (environment.language == "es" ? 1 : 0) }}>
                        {navItems.map((item) => (
                            <Button onClick={() => { showCatalog(item) }} key={item} sx={{
                                color: 'black',
                                '&:hover': {
                                    backgroundColor: '#F0F0F0',
                                    boxShadow: 'none',
                                },
                                fontSize: '14px', fontWeight: 400
                            }}>
                                {item}
                            </Button>
                        ))}
                    </Box>
                    <Box sx={{ flexGrow: 1 }} />
                    <Search sx={{ display: { sm: 'flex', xs: 'none' } }}>
                        <SearchIconWrapper>
                            <Iconify icon="charm:search" sx={{ width: "24px", height: "24px", color: "#000" }} />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search for product"
                            inputProps={{ 'aria-label': 'search' }}
                            sx={{ fontSize: '14px', fontWeight: 400 }}
                        />
                    </Search>
                    <Box sx={{ display: { sm: 'flex', xs: 'none' }, gap: 1 }}>
                        <IconButton size="large" color="inherit" sx={{ mr: 2 }}>
                            <FavoriteBorderIcon />
                        </IconButton>
                        <Link
                            component={RouterLink}
                            to='/sign-in'>
                            <IconButton
                                size="large"
                                edge="end"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                color="inherit"
                                sx={{ mr: 2 }}
                            >
                                <img src={userBorder} alt="" />
                            </IconButton>
                        </Link>
                        <Link
                            component={RouterLink}
                            to='/shoppingCart'>
                            <div style={{ position: 'relative' }}>
                                <IconButton
                                    size="large"
                                    color="inherit"
                                    sx={{ mr: 2 }}
                                >
                                    <img src={shoppingBag} alt="" />
                                    {cart.length > 0 && (
                                        <div style={{
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                            borderRadius: 100,
                                            backgroundColor: 'orange',
                                            height: 10,
                                            width: 10,
                                            fontSize: '8px'
                                        }}>
                                            {cart.length}
                                        </div>
                                    )}
                                </IconButton>
                            </div>
                        </Link>
                    </Box>
                    <Box sx={{ display: { sm: 'none', xs: 'flex' }, gap: 1 }}>
                        <IconButton size="large" color="inherit" sx={{ mr: 2 }}>
                            <FavoriteBorderIcon />
                        </IconButton>
                        <IconButton
                            size="large"
                            color="inherit"
                            sx={{ mr: 2 }}
                        >
                            <img src={searchIcon} alt="" />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box sx={{ backgroundColor: '#F0F0F0', mt: 0, display: { sm: 'flex', xs: 'none' } }}
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="3.5rem">
                <Typography sx={{ color: '#171520', fontSize: '16px', fontWeight: 300 }}>
                    {intl.formatMessage({id: 'home.texto-14', defaultMessage: 'Free shipping is valid on orders of $100 or more, after promotions and discounts are applied.'})}
                </Typography>

            </Box>
            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
        </Box>
    );
}
