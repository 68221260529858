
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { experimentalStyled as styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

//icons
import ic_location from '../../../src/assets/icons/ic_location.svg';
import ic_instagram from '../../../src/assets/icons/ic_instagram.svg';
import ic_facebook from '../../../src/assets/icons/ic_facebook.svg';
import ic_twitter from '../../../src/assets/icons/ic_twitter.svg';
import ic_youtube from '../../../src/assets/icons/ic_youtube.svg';
import logo_black from '../../../src/assets/logo_black.png';
//Redux
import { useSelector } from 'redux/store';
//intl
import { useIntl } from 'react-intl';

export default function Footer() {
    const intl = useIntl();
    const { environment } = useSelector((state) => state.nuDetectState);

    return (
        <Grid container spacing={2} sx={{
            backgroundColor: environment?.color, paddingTop: '1rem',
            paddingLeft: '3rem', paddingRight: '3rem', paddingBottom: '4rem', mt: { sm: 3, xs: 2 }, fontWeight: 100, fontSize: '18px'
        }}>
            <Box sx={{ display: { sm: 'none', xs: 'flex' } }} justifyContent='right' marginTop={2}>
                <img src={logo_black} alt="" width="100px" />
            </Box>
            <Grid item sm={1.5} xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '8px' }}>
                <Typography variant='subtitle1' sx={{ fontWeight: 300, color: 'white', mb: 2 }}>
                    {intl.formatMessage({ id: 'footer.texto-1', defaultMessage: 'Shop by category' })}
                </Typography>
                <Typography variant='subtitle1' sx={{ fontWeight: 300, color: '#B6B6B6' }}>
                    {intl.formatMessage({ id: 'footer.texto-2', defaultMessage: 'Women' })}
                </Typography>
                <Typography variant='subtitle1' sx={{ fontWeight: 300, color: '#B6B6B6' }}>
                    {intl.formatMessage({ id: 'footer.texto-3', defaultMessage: 'Men' })}
                </Typography>
                <Typography variant='subtitle1' sx={{ fontWeight: 300, color: '#B6B6B6' }}>
                    {intl.formatMessage({ id: 'footer.texto-4', defaultMessage: 'Accessories' })}
                </Typography>
                <Typography variant='subtitle1' sx={{ fontWeight: 300, color: '#B6B6B6' }}>
                    {intl.formatMessage({ id: 'footer.texto-5', defaultMessage: 'SALE' })}
                </Typography>
            </Grid>
            <Grid item sm={1.5} xs={12} pl={30} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '8px' }}>
                <Typography variant='subtitle1' sx={{ fontWeight: 300, color: 'white', mb: 2 }}>
                    {intl.formatMessage({ id: 'footer.texto-6', defaultMessage: 'About' })}
                </Typography>
                <Typography variant='subtitle1' sx={{ fontWeight: 300, color: '#B6B6B6' }}>
                    {intl.formatMessage({ id: 'footer.texto-7', defaultMessage: 'Contact Us' })}
                </Typography>
                <Typography variant='subtitle1' sx={{ fontWeight: 300, color: '#B6B6B6' }}>
                    {intl.formatMessage({ id: 'footer.texto-20', defaultMessage: 'About us' })}
                </Typography>
                <Typography variant='subtitle1' sx={{ fontWeight: 300, color: '#B6B6B6' }}>
                    {intl.formatMessage({ id: 'footer.texto-8', defaultMessage: 'Careers' })}
                </Typography>
                <Typography variant='subtitle1' sx={{ fontWeight: 300, color: '#B6B6B6' }}>
                    {intl.formatMessage({ id: 'footer.texto-9', defaultMessage: 'Press' })}
                </Typography>
            </Grid>
            <Grid item sm={1.5} xs={12} pl={0} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '8px' }}>
                <Typography variant='subtitle1' sx={{ fontWeight: 300, color: 'white', mb: 2 }}>
                    {intl.formatMessage({ id: 'footer.texto-10', defaultMessage: 'Policy' })}
                </Typography>
                <Typography variant='subtitle1' sx={{ fontWeight: 300, color: '#B6B6B6' }}>
                    {intl.formatMessage({ id: 'footer.texto-11', defaultMessage: 'Return Policy' })}
                </Typography>
                <Typography variant='subtitle1' sx={{ fontWeight: 300, color: '#B6B6B6' }}>
                    {intl.formatMessage({ id: 'footer.texto-12', defaultMessage: 'Terms of Use' })}
                </Typography>
                <Typography variant='subtitle1' sx={{ fontWeight: 300, color: '#B6B6B6' }}>
                    {intl.formatMessage({ id: 'footer.texto-13', defaultMessage: 'Sitemap' })}
                </Typography>
                <Typography variant='subtitle1' sx={{ fontWeight: 300, color: '#B6B6B6' }}>
                    {intl.formatMessage({ id: 'footer.texto-14', defaultMessage: 'Security' })}
                </Typography>
                <Typography variant='subtitle1' sx={{ fontWeight: 300, color: '#B6B6B6' }}>
                    {intl.formatMessage({ id: 'footer.texto-15', defaultMessage: 'Privacy' })}
                </Typography>
                <Typography variant='subtitle1' sx={{ fontWeight: 300, color: '#B6B6B6' }}>
                    {intl.formatMessage({ id: 'footer.texto-16', defaultMessage: 'EPR' })} {intl.formatMessage({ id: 'footer.texto-17', defaultMessage: 'Compliance' })}
                </Typography>
            </Grid>
            <Grid item sm={7.5} marginTop='inherit'>
                <Box sx={{ display: { sm: 'flex', xs: 'none' } }} justifyContent='right'>
                    <IconButton size="large" color="inherit" sx={{ mr: 2 }}>
                        <img src={ic_facebook} alt="" />
                    </IconButton>
                    <IconButton
                        size="large"
                        color="inherit"
                        sx={{ mr: 2 }}
                    >
                        <img src={ic_instagram} alt="" />
                    </IconButton>
                    <IconButton
                        size="large"
                        edge="end"
                        aria-haspopup="true"
                        color="inherit"
                        sx={{ mr: 2 }}
                    >
                        <img src={ic_twitter} alt="" />

                    </IconButton>
                    <IconButton
                        size="large"
                        edge="end"
                        aria-haspopup="true"
                        color="inherit"
                        sx={{ mr: 0 }}
                    >
                        <img src={ic_youtube} alt="" />

                    </IconButton>
                </Box>
                <Box sx={{ display: { sm: 'flex', xs: 'none' } }} justifyContent='right' marginTop={3.5}>
                    <Typography variant='subtitle2' sx={{ fontWeight: 300, color: 'white', mb: 1 }}>
                        <IconButton
                            size="large"
                            edge="end"
                            aria-haspopup="true"
                            color="inherit"
                            sx={{ mr: 1 }}
                        >
                            <img src={ic_location} alt="" />

                        </IconButton>
                        {intl.formatMessage({ id: 'footer.texto-18', defaultMessage: 'United States' })}
                    </Typography>
                </Box>
                <Typography variant='subtitle2' sx={{
                    fontWeight: 300, color: '#B6B6B6', mb: 2,
                    display: 'flex', justifyContent: 'flex-end'
                }}>
                    © 2022 |  {intl.formatMessage({ id: 'footer.texto-19', defaultMessage: 'OVERLAP All Rights Reserved' })}

                </Typography>
                <Box sx={{ display: { sm: 'flex', xs: 'none' } }} justifyContent='right' marginTop={4}>
                    <img src={logo_black} alt="" width="200px" />
                </Box>
            </Grid>
        </Grid>
    )
}