import { useMemo, ReactNode } from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import {
  createTheme,
  ThemeOptions,
  ThemeProvider as MUIThemeProvider,
  StyledEngineProvider
} from '@mui/material/styles';
//
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
//Redux
import { useDispatch, useSelector } from 'redux/store';
import {getDefaultEcommerceEnvironment, localStorageEnvironment} from 'redux/slices/NuDetect';
import { useEffect } from 'react';
//Internacionalizar sitio
import { IntlProvider } from 'react-intl';
import ObjetoLenguaje from 'resources/traducciones';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

export default function ThemeProvider({ children }: Props) {
  const dispatch = useDispatch();
    const { environment } = useSelector((state) => state.nuDetectState);

    useEffect(() =>{
      if(!localStorage.getItem('environment')){
        dispatch(getDefaultEcommerceEnvironment());
      }else{
        dispatch(localStorageEnvironment()); 
      }
    }, [])

  const themeOptions: ThemeOptions = useMemo(() => ({ palette, typography, breakpoints }), []);

  const theme = createTheme(themeOptions);

  theme.components = componentsOverride(theme);

  return (
    <IntlProvider locale={environment?.language || 'en'} messages={ObjetoLenguaje[environment?.language || 'en']}>
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
    </IntlProvider>
  );
}
