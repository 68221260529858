import { Theme } from '@mui/material/styles';
//Redux
import { useDispatch, useSelector } from 'redux/store';
// ----------------------------------------------------------------------
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    environment_outlined: true;
    environment_contained: true;
  }
}
export default function Button(theme: Theme) {

  const { environment } = useSelector((state) => state.nuDetectState);

  return {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            boxShadow: 'none'
          }
        },
        sizeLarge: {
          height: 48
        }
      },
      variants: [
        {
          props: { variant: 'environment_outlined' },
          style: {
            textTransform: 'none',
            border: `3px solid ${environment?.color || theme.palette.primary.main}`,
            color: environment?.color || theme.palette.primary.main,
          }
        },
        {
          props: { variant: 'environment_contained' },
          style: {
            textTransform: 'none',
            color: '#FFF',
            backgroundColor: environment?.color || theme.palette.primary.main,
          }
        }
      ],
    }
  };
}
