import { Suspense, lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
// components
import LoadingScreen from '../components/LoadingScreen';
import NavBar from 'sections/Ecommerce/NavBar';
import Container from '@mui/material/Container';
import Footer from 'sections/Ecommerce/Footer';

// ----------------------------------------------------------------------

const Loadable = (Component: React.ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return (
    <Suspense fallback={<LoadingScreen />}>
      <NavBar />
      <Container sx={{ minWidth: '100%' }}>
        <Component {...props} />
      </Container>
      <Footer />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Dashboard Routes
    {
      path: '/',
      element: <EcommerceHomeScreen />
    },
    {
      path: '/product_detail',
      element: <ProductDetail />
    },
    {
      path: '/shoppingCart',
      element: <ShoppingCartPage />
    },
    {
      path: '/catalog',
      element: <Catalog />
    },
    {
      path: '/sign-in',
      element: <SignIn />
    },
    {
      path: '/sign-up',
      element: <SignUp />
    },
    {
      path: '/placeOrder_1_user',
      element: <Checkout_1_user />
    },
    {
      path: '/shipping-address',
      element: <ShippingAddress />
    },
    {
      path: '/billing-address',
      element: <BillingAddress />
    },
    {
      path: '/payment-method',
      element: <PaymentMethod />
    },
    {
      path: '/my-profile',
      element: <UserInformation />
    },
    {
      path: '/order-placed',
      element: <OrderPlaced />
    },
    {
      path: 'password-reset',
      element: <PasswordReset />
    },

    { path: '*', element: <Navigate to="/" replace /> }
  ]);
}


// App
const EcommerceHomeScreen = Loadable(lazy(() => import('../pages/ecommerce/HomeScreen')));
const ProductDetail = Loadable(lazy(() => import('../pages/ecommerce/ProductDetail')));
const ShoppingCartPage = Loadable(lazy(() => import('../pages/ecommerce/ShoppingCartPage')));
const Catalog =  Loadable(lazy(() => import('../pages/ecommerce/Catalog')));
const Checkout_1_user =  Loadable(lazy(() => import('../pages/ecommerce/Checkout_1_user')));
const SignIn =  Loadable(lazy(() => import('../pages/auth/SignIn')));
const SignUp = Loadable(lazy(() => import('../pages/auth/SignUp')));
const UserInformation =  Loadable(lazy(() => import('../pages/ecommerce/UserInformation')));
const ShippingAddress =  Loadable(lazy(() => import('../pages/ecommerce/ShippingAddress')));
const BillingAddress =  Loadable(lazy(() => import('../pages/ecommerce/BillingAddress')));
const PaymentMethod =  Loadable(lazy(() => import('../pages/ecommerce/PaymentMethod')));
const OrderPlaced =  Loadable(lazy(() => import('../pages/ecommerce/OrderPlaced')));
const PasswordReset = Loadable(lazy(() => import('../pages/auth/PasswordReset')));