import { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/system';
import { keyframes } from '@emotion/react';
//
import { useSelector } from '../../redux/store';
// @mui
import { Fab, Button, Box, Dialog, DialogContent, BoxProps, IconButton } from '@mui/material';
import Iconify from '../../components/Iconify';
//
import Image from '../Image';
import ImgBackgroundTII from '../../assets/tii/img_background_tii.png';

// ----------------------------------------------------------------------

export default function DialogIdentityInsightsTransactions() {
  const { isOpenConsole, platform_security } = useSelector((state) => state.nuDetectState);

  const [open, setOpen] = useState(false);
  const [ref, setRef] = useState<{ onBack: (() => void) | null; onNext: (() => void) | null }>({
    onBack: null,
    onNext: null
  });
  const [active, setActive] = useState<string | null>(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setActive(null);
  };

  const onSetActive = (_active: string | null) => {
    setRef({ onBack: null, onNext: null });
    setActive(_active);
  };

  if (isOpenConsole || platform_security !== 'TransactionIdentityInsights') return null;

  return (
    <Box>
      <Fab
        color="default"
        onClick={handleOpen}
        sx={{
          backgroundColor: '#232326',
          '&:hover': {
            backgroundColor: '#37373b'
          }
        }}
      >
        <LogoMC width="60%" />
      </Fab>

      <Dialog
        open={open}
        onClose={handleClose}
        scroll="body"
        sx={{ '& .MuiDialog-paper': { minWidth: '1350px' } }}
      >
        <DialogContent sx={{ px: 0 }}>
          <Connector sx={{ px: 3, marginBottom: 2 }} />

          <PrincipalHeader sx={{ px: 3 }} />

          <Box
            sx={{
              py: 4,
              backgroundSize: 'cover',
              backgroundImage: `url(${ImgBackgroundTII})`,
              backgroundRepeat: 'no-repeat',
              minHeight: '700px'
            }}
          >
            {!active && <ActionDefault />}

            {active === 'pre-authentication-flow' && (
              <PreAuthenticationFlow setRef={setRef} onSetActive={onSetActive} />
            )}

            {active === 'acquirer-psp-psd2-eu-usecase' && (
              <AcquirerPSPPSD2EUUsecase setRef={setRef} onSetActive={onSetActive} />
            )}

            {active === 'post-authorization-use-case' && (
              <PostAuthorizationUsecase setRef={setRef} onSetActive={onSetActive} />
            )}
          </Box>

          <ActionsFooter
            active={active}
            onSetActive={onSetActive}
            handleClose={handleClose}
            onBack={ref?.onBack || undefined}
            onNext={ref?.onNext || undefined}
            sx={{ px: 3, pt: 1, pb: 2, position: 'relative', background: '#fff' }}
          />

          <Connector sx={{ px: 3 }} />
        </DialogContent>
      </Dialog>
    </Box>
  );
}

interface ActionDefaultProps {
  // onSetActive: (_active: string) => void;
}

function ActionDefault(props: ActionDefaultProps) {
  return (
    <Box>
      <Box
        component="span"
        sx={{
          fontSize: '24px',
          fontWeight: 400,
          lineHeight: '32px',
          letterSpacing: '0.016em',
          px: 3
        }}
      >
        How it works: IIT provides 3 types of insights – Identity, Device and Payment​
      </Box>

      <Connector sx={{ pl: 3, my: 2, maxWidth: '35%' }} />

      <Box
        component="p"
        sx={{
          fontSize: '20px',
          fontWeight: 400,
          lineHeight: '32px',
          letterSpacing: '0.016em',
          color: '#696972',
          mt: 0,
          mb: 3,
          px: 3
        }}
      >
        Identity Insights Transaction: Predictive signals and scores
      </Box>

      <Box
        sx={{
          display: 'grid',
          gap: 3,
          gridTemplateColumns: '1fr 600px',
          px: 3
        }}
      >
        <Box>
          <Box
            sx={{
              gap: 3,
              display: 'grid',
              gridTemplateColumns: '200px 200px 200px'
            }}
          >
            <Box sx={{ textAlign: 'center' }}>
              <Image
                src={require('../../assets/tii/default_1.png')}
                alt=""
                disabledEffect
                sx={{ mb: 1 }}
              />

              <Box
                component="p"
                sx={{
                  mt: 0,
                  mb: 1,
                  fontSize: '18px',
                  fontWeight: 400,
                  lineHeight: '32px',
                  letterSpacing: '0.016em',
                  color: '#696972'
                }}
              >
                Identity Risk Score
              </Box>
              <Box
                component="p"
                sx={{
                  my: 0,
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '24px',
                  letterSpacing: '0.016em',
                  color: '#232326'
                }}
              >
                How risky is this transaction?
              </Box>
            </Box>

            <Box sx={{ textAlign: 'center' }}>
              <Image
                src={require('../../assets/tii/default_2.png')}
                alt=""
                disabledEffect
                sx={{ mb: 1 }}
              />

              <Box
                component="p"
                sx={{
                  mt: 0,
                  mb: 1,
                  fontSize: '18px',
                  fontWeight: 400,
                  lineHeight: '32px',
                  letterSpacing: '0.016em',
                  color: '#696972'
                }}
              >
                IP Risk Score
              </Box>
              <Box
                component="p"
                sx={{
                  my: 0,
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '24px',
                  letterSpacing: '0.016em',
                  color: '#232326'
                }}
              >
                Does this IP address indicate risk?
              </Box>
            </Box>

            <Box sx={{ textAlign: 'center' }}>
              <Image
                src={require('../../assets/tii/default_6.png')}
                alt=""
                disabledEffect
                sx={{ mb: 1 }}
              />

              <Box
                component="p"
                sx={{
                  mt: 0,
                  mb: 1,
                  fontSize: '18px',
                  fontWeight: 400,
                  lineHeight: '32px',
                  letterSpacing: '0.016em',
                  color: '#696972'
                }}
              >
                Email Risk score
              </Box>
              <Box
                component="p"
                sx={{
                  my: 0,
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '24px',
                  letterSpacing: '0.016em',
                  color: '#232326'
                }}
              >
                How risky is this email?
              </Box>
            </Box>

            <Box sx={{ textAlign: 'center' }}>
              <Image
                src={require('../../assets/tii/default_3.png')}
                alt=""
                disabledEffect
                sx={{ mb: 1 }}
              />

              <Box
                component="p"
                sx={{
                  mt: 0,
                  mb: 1,
                  fontSize: '18px',
                  fontWeight: 400,
                  lineHeight: '32px',
                  letterSpacing: '0.016em',
                  color: '#696972'
                }}
              >
                Device Risk Score
              </Box>
              <Box
                component="p"
                sx={{
                  my: 0,
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '24px',
                  letterSpacing: '0.016em',
                  color: '#232326'
                }}
              >
                Is this Device associated with fraud?
              </Box>
            </Box>

            <Box sx={{ textAlign: 'center' }}>
              <Image
                src={require('../../assets/tii/default_4.png')}
                alt=""
                disabledEffect
                sx={{ mb: 1 }}
              />

              <Box
                component="p"
                sx={{
                  mt: 0,
                  mb: 1,
                  fontSize: '18px',
                  fontWeight: 400,
                  lineHeight: '32px',
                  letterSpacing: '0.016em',
                  color: '#696972'
                }}
              >
                Device Trust Score
              </Box>
              <Box
                component="p"
                sx={{
                  my: 0,
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '24px',
                  letterSpacing: '0.016em',
                  color: '#232326'
                }}
              >
                Are device settings authentic?
              </Box>
            </Box>

            <Box sx={{ textAlign: 'center' }}>
              <Image
                src={require('../../assets/tii/default_5.png')}
                alt=""
                disabledEffect
                sx={{ mb: 1 }}
              />

              <Box
                component="p"
                sx={{
                  mt: 0,
                  mb: 1,
                  fontSize: '18px',
                  fontWeight: 400,
                  lineHeight: '32px',
                  letterSpacing: '0.016em',
                  color: '#696972'
                }}
              >
                Device Insights Score
              </Box>
              <Box
                component="p"
                sx={{
                  my: 0,
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '24px',
                  letterSpacing: '0.016em',
                  color: '#232326'
                }}
              >
                Are we seeing high velocity or automation?
              </Box>
            </Box>
          </Box>
        </Box>

        <Box>
          <Box
            component="p"
            sx={{
              fontSize: '42px',
              fontWeight: 700,
              lineHeight: '32px',
              letterSpacing: '0.016em',
              color: '#3B3B3F',
              m: 0,
              mb: 5
            }}
          >
            Signals
          </Box>

          <Box
            sx={{
              display: 'grid',
              gap: 3,
              gridTemplateColumns: '1fr 1fr'
            }}
          >
            <Box
              sx={{
                display: 'grid',
                gap: 1
              }}
            >
              <Box
                sx={{
                  display: 'grid',
                  gap: 1,
                  gridTemplateColumns: '30px 1fr',
                  alignItems: 'center'
                }}
              >
                <Iconify
                  icon="material-symbols-light:mail-outline"
                  sx={{
                    fontSize: '28px'
                  }}
                />

                <Box
                  component="p"
                  sx={{
                    fontSize: '20px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    letterSpacing: '0.016em',
                    color: '#81818B',
                    m: 0
                  }}
                >
                  Email
                </Box>
              </Box>
              <Box
                component="p"
                sx={{
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '24px',
                  letterSpacing: '0.016em',
                  color: '#81818B',
                  m: 0
                }}
              >
                johnsmith@mastercard.com
              </Box>
            </Box>

            <Box
              sx={{
                display: 'grid',
                gap: 1
              }}
            >
              <Box
                sx={{
                  display: 'grid',
                  gap: 1,
                  gridTemplateColumns: '30px 1fr',
                  alignItems: 'center'
                }}
              >
                <Iconify
                  icon="ph:laptop-light"
                  sx={{
                    fontSize: '28px'
                  }}
                />

                <Box
                  component="p"
                  sx={{
                    fontSize: '20px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    letterSpacing: '0.016em',
                    color: '#81818B',
                    m: 0
                  }}
                >
                  Device
                </Box>
              </Box>
              <Box
                component="p"
                sx={{
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '24px',
                  letterSpacing: '0.016em',
                  color: '#81818B',
                  m: 0
                }}
              >
                Mac 15 Apple
              </Box>
            </Box>

            <Box
              sx={{
                display: 'grid',
                gap: 1
              }}
            >
              <Box
                sx={{
                  display: 'grid',
                  gap: 1,
                  gridTemplateColumns: '30px 1fr',
                  alignItems: 'center'
                }}
              >
                <Iconify
                  icon="ph:phone-light"
                  sx={{
                    fontSize: '28px'
                  }}
                />

                <Box
                  component="p"
                  sx={{
                    fontSize: '20px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    letterSpacing: '0.016em',
                    color: '#81818B',
                    m: 0
                  }}
                >
                  Phone
                </Box>
              </Box>
              <Box
                component="p"
                sx={{
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '24px',
                  letterSpacing: '0.016em',
                  color: '#81818B',
                  m: 0
                }}
              >
                +44 027-277-3821
              </Box>
            </Box>

            <Box
              sx={{
                display: 'grid',
                gap: 1
              }}
            >
              <Box
                sx={{
                  display: 'grid',
                  gap: 1,
                  gridTemplateColumns: '30px 1fr',
                  alignItems: 'center'
                }}
              >
                <Iconify
                  icon="ph:credit-card-light"
                  sx={{
                    fontSize: '28px'
                  }}
                />

                <Box
                  component="p"
                  sx={{
                    fontSize: '20px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    letterSpacing: '0.016em',
                    color: '#81818B',
                    m: 0
                  }}
                >
                  Card Name First seen
                </Box>
              </Box>
              <Box
                component="p"
                sx={{
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '24px',
                  letterSpacing: '0.016em',
                  color: '#81818B',
                  m: 0
                }}
              >
                300 Days
              </Box>
            </Box>

            <Box
              sx={{
                display: 'grid',
                gap: 1
              }}
            >
              <Box
                sx={{
                  display: 'grid',
                  gap: 1,
                  gridTemplateColumns: '30px 1fr',
                  alignItems: 'center'
                }}
              >
                <Iconify
                  icon="ph:map-pin"
                  sx={{
                    fontSize: '28px'
                  }}
                />

                <Box
                  component="p"
                  sx={{
                    fontSize: '20px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    letterSpacing: '0.016em',
                    color: '#81818B',
                    m: 0
                  }}
                >
                  Address
                </Box>
              </Box>
              <Box
                component="p"
                sx={{
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '24px',
                  letterSpacing: '0.016em',
                  color: '#81818B',
                  m: 0
                }}
              >
                10 Upper Bank St, London E14 5NP, United Kingdom
              </Box>
            </Box>

            <Box
              sx={{
                display: 'grid',
                gap: 1
              }}
            >
              <Box
                sx={{
                  display: 'grid',
                  gap: 1,
                  gridTemplateColumns: '30px 1fr',
                  alignItems: 'center'
                }}
              >
                <Iconify
                  icon="ph:credit-card-light"
                  sx={{
                    fontSize: '28px'
                  }}
                />

                <Box
                  component="p"
                  sx={{
                    fontSize: '20px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    letterSpacing: '0.016em',
                    color: '#81818B',
                    m: 0
                  }}
                >
                  Card Name Velocity
                </Box>
              </Box>
              <Box
                component="p"
                sx={{
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '24px',
                  letterSpacing: '0.016em',
                  color: '#81818B',
                  m: 0
                }}
              >
                12 Times
              </Box>
            </Box>

            <Box
              sx={{
                display: 'grid',
                gap: 1
              }}
            >
              <Box
                sx={{
                  display: 'grid',
                  gap: 1,
                  gridTemplateColumns: '30px 1fr',
                  alignItems: 'center'
                }}
              >
                <Iconify
                  icon="ph:wifi-medium-thin"
                  sx={{
                    fontSize: '28px'
                  }}
                />

                <Box
                  component="p"
                  sx={{
                    fontSize: '20px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    letterSpacing: '0.016em',
                    color: '#81818B',
                    m: 0
                  }}
                >
                  IP
                </Box>
              </Box>
              <Box
                component="p"
                sx={{
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '24px',
                  letterSpacing: '0.016em',
                  color: '#81818B',
                  m: 0
                }}
              >
                192.1.25.12
              </Box>
            </Box>

            <Box
              sx={{
                display: 'grid',
                gap: 1
              }}
            >
              <Box
                sx={{
                  display: 'grid',
                  gap: 1,
                  gridTemplateColumns: '30px 1fr',
                  alignItems: 'center'
                }}
              >
                <Iconify
                  icon="iconoir:lot-of-cash"
                  sx={{
                    fontSize: '28px'
                  }}
                />

                <Box
                  component="p"
                  sx={{
                    fontSize: '20px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    letterSpacing: '0.016em',
                    color: '#81818B',
                    m: 0
                  }}
                >
                  Amount Category ratio
                </Box>
              </Box>
              <Box
                component="p"
                sx={{
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '24px',
                  letterSpacing: '0.016em',
                  color: '#81818B',
                  m: 0
                }}
              >
                1.6
              </Box>
            </Box>
          </Box>
        </Box>

        {/* <Image src={require('../../assets/tii/default.png')} alt="" disabledEffect /> */}
      </Box>
    </Box>
  );
}

interface LogoMCProps {
  width: string;
}

function LogoMC({ width }: LogoMCProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} viewBox="0 0 256 170">
      <path fill="#ff5f00" d="M93.298 16.903h69.15v124.251h-69.15z" />
      <path
        fill="#eb001b"
        d="M97.689 79.029c0-25.245 11.854-47.637 30.074-62.126C114.373 6.366 97.47 0 79.03 0C35.343 0 0 35.343 0 79.029c0 43.685 35.343 79.029 79.029 79.029c18.44 0 35.343-6.366 48.734-16.904c-18.22-14.269-30.074-36.88-30.074-62.125"
      />
      <path
        fill="#f79e1b"
        d="M255.746 79.029c0 43.685-35.343 79.029-79.029 79.029c-18.44 0-35.343-6.366-48.734-16.904c18.44-14.488 30.075-36.88 30.075-62.125c0-25.245-11.855-47.637-30.075-62.126C141.373 6.366 158.277 0 176.717 0c43.686 0 79.03 35.563 79.03 79.029"
      />
    </svg>
  );
}

interface ConnectorProps extends BoxProps {}

function Connector({ sx }: ConnectorProps) {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '5px 1fr 5px',
        alignItems: 'center',
        ...sx
      }}
    >
      <Box
        sx={{
          width: '5px',
          height: '5px',
          borderRadius: '50%',
          backgroundColor: '#81818B'
        }}
      />
      <Box
        sx={{
          zIndex: 1,
          height: '1px',
          backgroundColor: '#81818B'
        }}
      />
      <Box
        sx={{
          width: '5px',
          height: '5px',
          borderRadius: '50%',
          backgroundColor: '#81818B'
        }}
      />
    </Box>
  );
}

interface PrincipalHeaderProps extends BoxProps {}

function PrincipalHeader({ sx }: PrincipalHeaderProps) {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr auto auto auto auto',
        alignItems: 'center',
        gap: 2,
        ...sx
      }}
    >
      <Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '60px 1fr',
            alignItems: 'center',
            gap: 2
            // maxWidth: '420px'
          }}
        >
          <LogoMC width="60px" />

          <Box
            component="span"
            sx={{
              fontSize: '22px',
              fontWeight: '400',
              lineHeight: '32px',
              letterSpacing: '0.016em',
              textTransform: 'uppercase'
            }}
          >
            Identity Insights for Transactions
          </Box>
        </Box>

        <Connector sx={{ maxWidth: '65%', marginTop: 2 }} />
      </Box>

      <Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr auto auto',
            alignItems: 'center',
            gap: 1,
            maxWidth: '160px',
            marginBottom: 1
          }}
        >
          <Box
            component="span"
            sx={{
              fontSize: '10px',
              fontWeight: 400,
              lineHeight: '12px',
              letterSpacing: '0.016em',
              textAlign: 'right',
              color: '#81818B',
              textTransform: 'uppercase'
            }}
          >
            Identity analysis time
          </Box>
          <Box
            component="span"
            sx={{
              fontSize: '24px',
              fontWeight: 400,
              lineHeight: '32px',
              letterSpacing: '0.016em',
              color: '#696972',
              textTransform: 'uppercase'
            }}
          >
            50
          </Box>
          <Box
            component="span"
            sx={{
              fontSize: '10px',
              fontWeight: 400,
              lineHeight: '16px',
              letterSpacing: '0.016em',
              color: '#000000',
              textTransform: 'uppercase'
            }}
          >
            MS
          </Box>
        </Box>

        <Connector />
      </Box>

      <Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr auto auto',
            alignItems: 'center',
            gap: 1,
            maxWidth: '160px',
            marginBottom: 1
          }}
        >
          <Box
            component="span"
            sx={{
              fontSize: '10px',
              fontWeight: 400,
              lineHeight: '12px',
              letterSpacing: '0.016em',
              textAlign: 'right',
              color: '#81818B',
              textTransform: 'uppercase'
            }}
          >
            IA - RISK Analytics
          </Box>
          <Box
            component="span"
            sx={{
              fontSize: '24px',
              fontWeight: 400,
              lineHeight: '32px',
              letterSpacing: '0.016em',
              color: '#696972',
              textTransform: 'uppercase'
            }}
          >
            ON
          </Box>
          <Box
            component="span"
            sx={{
              width: '8px',
              height: '8px',
              backgroundColor: '#188A16',
              borderRadius: '50%'
            }}
          />
        </Box>

        <Connector />
      </Box>

      <Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr auto auto',
            alignItems: 'center',
            gap: 1,
            maxWidth: '160px',
            marginBottom: 1
          }}
        >
          <Box
            component="span"
            sx={{
              fontSize: '10px',
              fontWeight: 400,
              lineHeight: '12px',
              letterSpacing: '0.016em',
              textAlign: 'right',
              color: '#81818B',
              textTransform: 'uppercase'
            }}
          >
            Dynamic identity data
          </Box>
          <Box
            component="span"
            sx={{
              fontSize: '24px',
              fontWeight: 400,
              lineHeight: '32px',
              letterSpacing: '0.016em',
              color: '#696972',
              textTransform: 'uppercase'
            }}
          >
            ON
          </Box>
          <Box
            component="span"
            sx={{
              width: '8px',
              height: '8px',
              backgroundColor: '#188A16',
              borderRadius: '50%'
            }}
          />
        </Box>

        <Connector />
      </Box>

      <Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr auto auto',
            alignItems: 'center',
            gap: 1,
            maxWidth: '160px',
            marginBottom: 1
          }}
        >
          <Box
            component="span"
            sx={{
              fontSize: '10px',
              fontWeight: 400,
              lineHeight: '12px',
              letterSpacing: '0.016em',
              textAlign: 'right',
              color: '#81818B',
              textTransform: 'uppercase'
            }}
          >
            Dynamic Experience
          </Box>
          <Box
            component="span"
            sx={{
              fontSize: '24px',
              fontWeight: 400,
              lineHeight: '32px',
              letterSpacing: '0.016em',
              color: '#696972',
              textTransform: 'uppercase'
            }}
          >
            ON
          </Box>
          <Box
            component="span"
            sx={{
              width: '8px',
              height: '8px',
              backgroundColor: '#188A16',
              borderRadius: '50%'
            }}
          />
        </Box>

        <Connector />
      </Box>
    </Box>
  );
}

interface ActionsFooterProps extends BoxProps {
  active: string | null;
  onSetActive: (_active: string) => void;
  handleClose: () => void;
  onBack: (() => void) | undefined;
  onNext: (() => void) | undefined;
}

function ActionsFooter({
  active,
  onSetActive,
  handleClose,
  onBack,
  onNext,
  sx
}: ActionsFooterProps) {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: active ? 'auto 1fr auto auto auto auto' : '1fr auto auto auto auto',
        alignItems: 'center',
        gap: 2,
        ...sx
      }}
    >
      {active && (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'auto auto',
            alignItems: 'center',
            gap: 1
          }}
        >
          <IconButton
            color="default"
            onClick={onBack}
            size="medium"
            sx={{
              p: (theme) => `${theme.spacing(1)}!important`,
              color: '#232326',
              backgroundColor: 'rgba(143, 154, 178, 0.6)',
              '&:hover': {
                backgroundColor: 'rgba(143, 154, 178, 0.3)'
              }
            }}
          >
            <Iconify icon="mingcute:left-fill" />
          </IconButton>

          <IconButton
            color="default"
            onClick={onNext}
            size="medium"
            sx={{
              p: (theme) => `${theme.spacing(1)}!important`,
              color: '#232326',
              backgroundColor: 'rgba(143, 154, 178, 0.6)',
              '&:hover': {
                backgroundColor: 'rgba(143, 154, 178, 0.3)'
              }
            }}
          >
            <Iconify icon="mingcute:right-fill" />
          </IconButton>
        </Box>
      )}

      <Box />

      <Button
        variant="contained"
        sx={{
          py: (theme) => `${theme.spacing(1.25)}!important`,
          px: (theme) => `${theme.spacing(2)}!important`,
          backgroundColor: '#232326',
          '&:hover': {
            backgroundColor: '#37373b'
          },
          ...(active === 'pre-authentication-flow' && {
            color: '#FFFFFF',
            backgroundColor: '#FF5F00',
            '&:hover': {
              backgroundColor: '#f76711'
            }
          })
        }}
        onClick={() => onSetActive('pre-authentication-flow')}
      >
        Pre-authentication flow
      </Button>

      <Button
        variant="contained"
        sx={{
          py: (theme) => `${theme.spacing(1.25)}!important`,
          px: (theme) => `${theme.spacing(2)}!important`,
          backgroundColor: '#232326',
          '&:hover': {
            backgroundColor: '#37373b'
          },
          ...(active === 'acquirer-psp-psd2-eu-usecase' && {
            color: '#FFFFFF',
            backgroundColor: '#FF5F00',
            '&:hover': {
              backgroundColor: '#f76711'
            }
          })
        }}
        onClick={() => onSetActive('acquirer-psp-psd2-eu-usecase')}
      >
        Acquirer PSP - PSD2 EU usecase
      </Button>

      <Button
        variant="contained"
        sx={{
          py: (theme) => `${theme.spacing(1.25)}!important`,
          px: (theme) => `${theme.spacing(2)}!important`,
          backgroundColor: '#232326',
          '&:hover': {
            backgroundColor: '#37373b'
          },
          ...(active === 'post-authorization-use-case' && {
            color: '#FFFFFF',
            backgroundColor: '#FF5F00',
            '&:hover': {
              backgroundColor: '#f76711'
            }
          })
        }}
        onClick={() => onSetActive('post-authorization-use-case')}
      >
        Post-Authentication use case
      </Button>

      <IconButton
        color="default"
        onClick={handleClose}
        size="medium"
        sx={{
          p: (theme) => `${theme.spacing(1)}!important`,
          backgroundColor: '#232326',
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#37373b'
          }
        }}
      >
        <Iconify icon="mingcute:close-fill" />
      </IconButton>
    </Box>
  );
}

// ----------------------------------

const animations = [
  {
    fade: {
      in: keyframes`
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      `,
      out: keyframes`
        0% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      `
    },
    duration: '2s'
  }
  // {
  //   keyframe: keyframes`
  //     0% {
  //       opacity: 1;
  //       grid-template-columns: 10px 5px;
  //     }
  //     100% {
  //       opacity: 1;
  //       grid-template-columns: 195px 5px;
  //     }
  //   `,
  //   delay: '2s',
  //   duration: '1s',
  //   easing: 'ease-in-out'
  // }
];

interface AnimatedBoxProps {
  animate: boolean;
  animationIndex: number;
  fadeIn: boolean;
  fadeOut: boolean;
}

const AnimatedBox = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'animate' && prop !== 'animationIndex' && prop !== 'fadeIn' && prop !== 'fadeOut'
})<AnimatedBoxProps>(({ animate, animationIndex, fadeIn, fadeOut }) => {
  const { fade, duration } = animations[animationIndex];

  let animation = '';
  let opacity = 0;
  if (fadeIn) {
    animation = `${fade.in} ${duration} forwards`;
    opacity = 0;
  }
  if (fadeOut) {
    animation = `${fade.out} ${duration} forwards`;
    opacity = 1;
  }

  return {
    opacity, // Inicialmente oculto
    ...(animate &&
      (fadeIn || fadeOut) && {
        animation,
        animationFillMode: 'forwards'
      })
  };
});

// ----------------------------------

interface PreAuthenticationFlowProps {
  onSetActive: (_active: string | null) => void;
  setRef: (funciones: { onNext: () => void; onBack: () => void }) => void;
}

function PreAuthenticationFlow({ onSetActive, setRef }: PreAuthenticationFlowProps) {
  const [animate, setAnimate] = useState<
    {
      id: number;
      fadeIn: boolean;
      fadeOut: boolean;
    }[]
  >([
    { id: 1, fadeIn: false, fadeOut: false },
    { id: 2, fadeIn: false, fadeOut: false },
    { id: 3, fadeIn: false, fadeOut: false },
    { id: 4, fadeIn: false, fadeOut: false },
    { id: 5, fadeIn: false, fadeOut: false }
  ]);

  const onNext = () => {
    const index = animate.sort((a, b) => a.id - b.id).findIndex((a) => !a.fadeIn); // Find first item not faded in
    if (index !== -1) {
      const updatedItems = [...animate];
      updatedItems[index] = { ...updatedItems[index], fadeIn: true, fadeOut: false };
      setAnimate(updatedItems);
      console.log(`Next executed for item ${index + 1}`);
    }
  };

  const onBack = () => {
    const index = animate.sort((a, b) => b.id - a.id).findIndex((a) => a.fadeIn); // Find first item faded in
    if (index !== -1) {
      const updatedItems = [...animate];
      updatedItems[index] = { ...updatedItems[index], fadeOut: true, fadeIn: false };
      setAnimate(updatedItems.sort((a, b) => a.id - b.id));
      console.log(`Back executed for item ${index + 1}`);
    }
  };

  // Pasar las referencias de las funciones al componente padre
  useEffect(() => {
    setRef({ onNext, onBack });
  }, [setRef, animate]);

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      switch (event.key) {
        case 'ArrowLeft':
          onBack();
          break;
        case 'ArrowRight':
          onNext();
          break;
        default:
          break;
      }
    };

    // Agregar el event listener cuando el componente se monta
    window.addEventListener('keydown', handleKeyDown);

    // Eliminar el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [animate, onNext, onNext]);

  return (
    <Box sx={{ px: 3 }}>
      <Box sx={{ display: 'grid', gridTemplateColumns: '90px 1fr', gap: 2 }}>
        <Button
          variant="outlined"
          startIcon={<Iconify icon="ph:arrow-left" />}
          onClick={() => onSetActive(null)}
        >
          Back
        </Button>

        <Box
          component="span"
          sx={{
            fontSize: '24px',
            fontWeight: 400,
            lineHeight: '32px',
            letterSpacing: '0.016em'
          }}
        >
          Pre-authentication flow
        </Box>
      </Box>

      <Connector sx={{ my: 2, maxWidth: '33%' }} />

      <Box sx={{ display: 'grid', gridTemplateColumns: '340px 1fr', gap: 2 }}>
        <Image
          src={require('../../assets/tii/pre_authentication_flow.png')}
          alt=""
          disabledEffect
        />

        <Box>
          <Box
            sx={{ display: 'grid', gridTemplateColumns: '200px 200px 200px 80px 200px ', gap: 2 }}
          >
            <AnimatedBox
              animate={true}
              fadeIn={animate.find(({ id }) => id == 1)?.fadeIn || false}
              fadeOut={animate.find(({ id }) => id == 1)?.fadeOut || false}
              animationIndex={0}
              textAlign="center"
            >
              <Image
                src={require('../../assets/tii/pre_authentication_flow_1.png')}
                alt=""
                disabledEffect
                sx={{ mb: 1 }}
              />

              <Box
                component="p"
                sx={{
                  mt: 0,
                  mb: 1,
                  fontSize: '18px',
                  fontWeight: 400,
                  lineHeight: '32px',
                  letterSpacing: '0.016em',
                  color: '#696972'
                }}
              >
                Consumer
              </Box>
              <Box
                component="p"
                sx={{
                  my: 0,
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '24px',
                  letterSpacing: '0.016em',
                  color: '#232326'
                }}
              >
                The consumer initiates the transaction with the merchant.
              </Box>

              <Connector sx={{ mt: 1 }} />
            </AnimatedBox>

            <Box>
              <AnimatedBox
                animate={true}
                fadeIn={animate.find(({ id }) => id == 2)?.fadeIn || false}
                fadeOut={animate.find(({ id }) => id == 2)?.fadeOut || false}
                animationIndex={0}
                sx={{
                  display: 'grid',
                  // gridTemplateColumns: '10px 5px',
                  gridTemplateColumns: '195px 5px',
                  gap: 0,
                  marginTop: 6
                }}
              >
                <Box sx={{ mt: 0.25, border: 'none', borderTop: '1px dashed #3B3B3F' }} />
                <Box
                  sx={{
                    width: '5px',
                    height: '5px',
                    transform: 'rotate(-45deg)',
                    borderLeft: 'none',
                    borderTop: 'none',
                    borderRight: '1px #3B3B3F solid',
                    borderBottom: '1px #3B3B3F solid'
                  }}
                />
              </AnimatedBox>

              <AnimatedBox
                animate={true}
                fadeIn={animate.find(({ id }) => id == 3)?.fadeIn || false}
                fadeOut={animate.find(({ id }) => id == 3)?.fadeOut || false}
                animationIndex={0}
                sx={{
                  position: 'relative',
                  mt: 9,
                  height: '30px',
                  mb: 2,
                  width: '50%',
                  margin: '72px 0 16px auto',
                  border: 'none',
                  borderTop: '1px dashed #3B3B3F',
                  borderLeft: '1px dashed #3B3B3F'
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    width: '5px',
                    height: '5px',
                    transform: 'rotate(-45deg)',
                    borderLeft: 'none',
                    borderTop: 'none',
                    borderRight: '1px #3B3B3F solid',
                    borderBottom: '1px #3B3B3F solid',
                    right: '-3px',
                    top: '-3px'
                  }}
                />

                <Box
                  sx={{
                    position: 'absolute',
                    width: '5px',
                    height: '5px',
                    transform: 'rotate(45deg)',
                    borderLeft: 'none',
                    borderTop: 'none',
                    borderRight: '1px #3B3B3F solid',
                    borderBottom: '1px #3B3B3F solid',
                    bottom: '-3px',
                    left: '-3px'
                  }}
                />
              </AnimatedBox>

              <AnimatedBox
                animate={true}
                fadeIn={animate.find(({ id }) => id == 3)?.fadeIn || false}
                fadeOut={animate.find(({ id }) => id == 3)?.fadeOut || false}
                animationIndex={0}
                textAlign="center"
              >
                <Image
                  src={require('../../assets/tii/pre_authentication_flow_2.png')}
                  alt=""
                  disabledEffect
                  sx={{ mb: 1 }}
                />

                <Box
                  component="p"
                  sx={{
                    mt: 0,
                    mb: 1,
                    fontSize: '18px',
                    fontWeight: 400,
                    lineHeight: '32px',
                    letterSpacing: '0.016em',
                    color: '#696972'
                  }}
                >
                  IIT API
                </Box>

                <Connector sx={{ mt: 1, maxWidth: '60%', mx: 'auto' }} />
              </AnimatedBox>
            </Box>

            <AnimatedBox
              animate={true}
              fadeIn={animate.find(({ id }) => id == 2)?.fadeIn || false}
              fadeOut={animate.find(({ id }) => id == 2)?.fadeOut || false}
              animationIndex={0}
              textAlign="center"
            >
              <Image
                src={require('../../assets/tii/pre_authentication_flow_3.png')}
                alt=""
                disabledEffect
                sx={{ mb: 1 }}
              />

              <Box
                component="p"
                sx={{
                  mt: 0,
                  mb: 1,
                  fontSize: '18px',
                  fontWeight: 400,
                  lineHeight: '32px',
                  letterSpacing: '0.016em',
                  color: '#696972'
                }}
              >
                Merchant
              </Box>
              <Box
                component="p"
                sx={{
                  my: 0,
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '24px',
                  letterSpacing: '0.016em',
                  color: '#232326'
                }}
              >
                The merchant queries the Identity Insights Transaction API directly or via a partner
                to gain insights on the transaction.
              </Box>

              <Connector sx={{ mt: 1 }} />
            </AnimatedBox>

            <AnimatedBox
              animate={true}
              fadeIn={animate.find(({ id }) => id == 4)?.fadeIn || false}
              fadeOut={animate.find(({ id }) => id == 4)?.fadeOut || false}
              animationIndex={0}
            >
              <Box sx={{ display: 'grid', gridTemplateColumns: '75px 5px', gap: 0, mt: 6 }}>
                <Box sx={{ mt: 0.25, border: 'none', borderTop: '1px dashed #3B3B3F' }} />
                <Box
                  sx={{
                    width: '5px',
                    height: '5px',
                    transform: 'rotate(-45deg)',
                    borderLeft: 'none',
                    borderTop: 'none',
                    borderRight: '1px #3B3B3F solid',
                    borderBottom: '1px #3B3B3F solid'
                  }}
                />
              </Box>
            </AnimatedBox>

            <AnimatedBox
              animate={true}
              fadeIn={animate.find(({ id }) => id == 4)?.fadeIn || false}
              fadeOut={animate.find(({ id }) => id == 4)?.fadeOut || false}
              animationIndex={0}
              textAlign="center"
            >
              <Image
                src={require('../../assets/tii/pre_authentication_flow_4.png')}
                alt=""
                disabledEffect
                sx={{ mb: 1 }}
              />

              <Box
                component="p"
                sx={{
                  mt: 0,
                  mb: 1,
                  fontSize: '18px',
                  fontWeight: 400,
                  lineHeight: '32px',
                  letterSpacing: '0.016em',
                  color: '#696972'
                }}
              >
                Risk decisioning
              </Box>
              <Box
                component="p"
                sx={{
                  my: 0,
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '24px',
                  letterSpacing: '0.016em',
                  color: '#232326'
                }}
              >
                The merchant leverages the insights for risk decisioning to determine how to proceed
                with the transaction.
              </Box>

              <Connector sx={{ mt: 1 }} />
            </AnimatedBox>
          </Box>

          <AnimatedBox
            animate={true}
            fadeIn={animate.find(({ id }) => id == 5)?.fadeIn || false}
            fadeOut={animate.find(({ id }) => id == 5)?.fadeOut || false}
            animationIndex={0}
            sx={{ marginTop: '30px' }}
          >
            <Box
              sx={{
                position: 'relative',
                width: '432px',
                marginLeft: '280px',
                height: '30px',
                border: '1px solid #3B3B3F',
                borderBottom: 'unset'
              }}
            >
              <Box
                sx={{
                  height: '60px',
                  width: '345px',
                  position: 'absolute',
                  left: '90%',
                  transform: 'translate(-50%, -50%)',
                  border: '1px solid #3B3B3F',
                  borderBottom: 'unset',
                  borderRight: 'unset'
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    height: '47px',
                    width: '1px',
                    right: 0,
                    backgroundColor: '#3B3B3F',
                    top: '-47px'
                  }}
                ></Box>
              </Box>
            </Box>
          </AnimatedBox>

          <AnimatedBox
            animate={true}
            fadeIn={animate.find(({ id }) => id == 5)?.fadeIn || false}
            fadeOut={animate.find(({ id }) => id == 5)?.fadeOut || false}
            animationIndex={0}
            sx={{
              display: 'grid',
              gridTemplateColumns: '200px 200px 200px',
              gap: 2,
              marginLeft: '180px'
            }}
          >
            <Box sx={{ textAlign: 'center' }}>
              <Image
                src={require('../../assets/tii/pre_authentication_flow_5.png')}
                alt=""
                disabledEffect
                sx={{ mb: 1 }}
              />

              <Box
                component="p"
                sx={{
                  mt: 0,
                  mb: 1,
                  fontSize: '18px',
                  fontWeight: 400,
                  lineHeight: '32px',
                  letterSpacing: '0.016em',
                  color: '#696972'
                }}
              >
                Low risk
              </Box>
              <Box
                component="p"
                sx={{
                  my: 0,
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '24px',
                  letterSpacing: '0.016em',
                  color: '#232326'
                }}
              >
                Proceed to authorization
              </Box>
            </Box>

            <Box sx={{ textAlign: 'center' }}>
              <Image
                src={require('../../assets/tii/pre_authentication_flow_6.png')}
                alt=""
                disabledEffect
                sx={{ mb: 1 }}
              />

              <Box
                component="p"
                sx={{
                  mt: 0,
                  mb: 1,
                  fontSize: '18px',
                  fontWeight: 400,
                  lineHeight: '32px',
                  letterSpacing: '0.016em',
                  color: '#696972'
                }}
              >
                Elevated risk
              </Box>
              <Box
                component="p"
                sx={{
                  my: 0,
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '24px',
                  letterSpacing: '0.016em',
                  color: '#232326'
                }}
              >
                Proceed to authentication
              </Box>
            </Box>

            <Box sx={{ textAlign: 'center' }}>
              <Image
                src={require('../../assets/tii/pre_authentication_flow_7.png')}
                alt=""
                disabledEffect
                sx={{ mb: 1 }}
              />

              <Box
                component="p"
                sx={{
                  mt: 0,
                  mb: 1,
                  fontSize: '18px',
                  fontWeight: 400,
                  lineHeight: '32px',
                  letterSpacing: '0.016em',
                  color: '#696972'
                }}
              >
                Substantial risk
              </Box>
              <Box
                component="p"
                sx={{
                  my: 0,
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '24px',
                  letterSpacing: '0.016em',
                  color: '#232326'
                }}
              >
                Refuse checkout
              </Box>
            </Box>
          </AnimatedBox>
        </Box>
      </Box>
    </Box>
  );
}

// ----------------------------------

interface AcquirerPSPPSD2EUUsecaseProps {
  onSetActive: (_active: string | null) => void;
  setRef: (funciones: { onNext: () => void; onBack: () => void }) => void;
}

function AcquirerPSPPSD2EUUsecase({ onSetActive, setRef }: AcquirerPSPPSD2EUUsecaseProps) {
  const [animate, setAnimate] = useState<
    {
      id: number;
      fadeIn: boolean;
      fadeOut: boolean;
    }[]
  >([
    { id: 1, fadeIn: false, fadeOut: false },
    { id: 2, fadeIn: false, fadeOut: false },
    { id: 3, fadeIn: false, fadeOut: false },
    { id: 4, fadeIn: false, fadeOut: false },
    { id: 5, fadeIn: false, fadeOut: false }
  ]);

  const onNext = () => {
    const index = animate.sort((a, b) => a.id - b.id).findIndex((a) => !a.fadeIn); // Find first item not faded in
    if (index !== -1) {
      const updatedItems = [...animate];
      updatedItems[index] = { ...updatedItems[index], fadeIn: true, fadeOut: false };
      setAnimate(updatedItems);
      console.log(`Next executed for item ${index + 1}`);
    }
  };

  const onBack = () => {
    const index = animate.sort((a, b) => b.id - a.id).findIndex((a) => a.fadeIn); // Find first item faded in
    if (index !== -1) {
      const updatedItems = [...animate];
      updatedItems[index] = { ...updatedItems[index], fadeOut: true, fadeIn: false };
      setAnimate(updatedItems.sort((a, b) => a.id - b.id));
      console.log(`Back executed for item ${index + 1}`);
    }
  };

  // Pasar las referencias de las funciones al componente padre
  useEffect(() => {
    setRef({ onNext, onBack });
  }, [setRef, animate]);

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      switch (event.key) {
        case 'ArrowLeft':
          onBack();
          break;
        case 'ArrowRight':
          onNext();
          break;
        default:
          break;
      }
    };

    // Agregar el event listener cuando el componente se monta
    window.addEventListener('keydown', handleKeyDown);

    // Eliminar el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [animate, onNext, onNext]);

  return (
    <Box sx={{ px: 3 }}>
      <Box sx={{ display: 'grid', gridTemplateColumns: '90px 1fr', gap: 2 }}>
        <Button
          variant="outlined"
          startIcon={<Iconify icon="ph:arrow-left" />}
          onClick={() => onSetActive(null)}
        >
          Back
        </Button>

        <Box
          component="span"
          sx={{
            fontSize: '24px',
            fontWeight: 400,
            lineHeight: '32px',
            letterSpacing: '0.016em'
          }}
        >
          Acquirer PSP - PSD2 usecase (EU Specific)
        </Box>
      </Box>

      <Connector sx={{ my: 2, maxWidth: '33%' }} />

      <Box sx={{ display: 'grid', gridTemplateColumns: '200px 80px 200px 200px 200px ', gap: 2 }}>
        <AnimatedBox
          animate={true}
          fadeIn={animate.find(({ id }) => id == 1)?.fadeIn || false}
          fadeOut={animate.find(({ id }) => id == 1)?.fadeOut || false}
          animationIndex={0}
          textAlign="center"
        >
          <Image
            src={require('../../assets/tii/acquirer_psp_1.png')}
            alt=""
            disabledEffect
            sx={{ mb: 1 }}
          />

          <Box
            component="p"
            sx={{
              mt: 0,
              mb: 1,
              fontSize: '18px',
              fontWeight: 400,
              lineHeight: '32px',
              letterSpacing: '0.016em',
              color: '#696972'
            }}
          >
            Consumer
          </Box>
          <Box
            component="p"
            sx={{
              my: 0,
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '24px',
              letterSpacing: '0.016em',
              color: '#232326'
            }}
          >
            PSD2 compliant businesses want to improve the consumer experience and reduce
            friction.  ​
          </Box>

          <Connector sx={{ mt: 1 }} />
        </AnimatedBox>

        <AnimatedBox
          animate={true}
          fadeIn={animate.find(({ id }) => id == 2)?.fadeIn || false}
          fadeOut={animate.find(({ id }) => id == 2)?.fadeOut || false}
          animationIndex={0}
        >
          <Box sx={{ display: 'grid', gridTemplateColumns: '75px 5px', gap: 0, mt: 6 }}>
            <Box sx={{ mt: 0.25, border: 'none', borderTop: '1px dashed #3B3B3F' }} />
            <Box
              sx={{
                width: '5px',
                height: '5px',
                transform: 'rotate(-45deg)',
                borderLeft: 'none',
                borderTop: 'none',
                borderRight: '1px #3B3B3F solid',
                borderBottom: '1px #3B3B3F solid'
              }}
            />
          </Box>
        </AnimatedBox>

        <AnimatedBox
          animate={true}
          fadeIn={animate.find(({ id }) => id == 2)?.fadeIn || false}
          fadeOut={animate.find(({ id }) => id == 2)?.fadeOut || false}
          animationIndex={0}
          textAlign="center"
        >
          <Image
            src={require('../../assets/tii/acquirer_psp_2.png')}
            alt=""
            disabledEffect
            sx={{ mb: 1 }}
          />

          <Box
            component="p"
            sx={{
              mt: 0,
              mb: 1,
              fontSize: '18px',
              fontWeight: 400,
              lineHeight: '32px',
              letterSpacing: '0.016em',
              color: '#696972'
            }}
          >
            Merchant
          </Box>
          <Box
            component="p"
            sx={{
              my: 0,
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '24px',
              letterSpacing: '0.016em',
              color: '#232326'
            }}
          >
            Helps leverage Acquirer TRA exemptions to optimize transaction flows and meet PSD2
            requirements
          </Box>

          <Connector sx={{ mt: 1 }} />
        </AnimatedBox>

        <Box>
          <AnimatedBox
            animate={true}
            fadeIn={animate.find(({ id }) => id == 3)?.fadeIn || false}
            fadeOut={animate.find(({ id }) => id == 3)?.fadeOut || false}
            animationIndex={0}
            sx={{ display: 'grid', gridTemplateColumns: '195px 5px', gap: 0, mt: 6 }}
          >
            <Box sx={{ mt: 0.25, border: 'none', borderTop: '1px dashed #3B3B3F' }} />
            <Box
              sx={{
                width: '5px',
                height: '5px',
                transform: 'rotate(-45deg)',
                borderLeft: 'none',
                borderTop: 'none',
                borderRight: '1px #3B3B3F solid',
                borderBottom: '1px #3B3B3F solid'
              }}
            />
          </AnimatedBox>

          <AnimatedBox
            animate={true}
            fadeIn={animate.find(({ id }) => id == 4)?.fadeIn || false}
            fadeOut={animate.find(({ id }) => id == 4)?.fadeOut || false}
            animationIndex={0}
            sx={{ position: 'relative', mt: 9, mb: 2 }}
          >
            <Box
              sx={{
                position: 'relative',
                height: '1px',
                border: 'none',
                borderTop: '1px dashed #3B3B3F',
                borderLeft: '1px dashed #3B3B3F'
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  width: '5px',
                  height: '5px',
                  transform: 'rotate(-45deg)',
                  border: 'none',
                  borderRight: '1px #3B3B3F solid',
                  borderBottom: '1px #3B3B3F solid',
                  right: '-3px',
                  top: '-3px'
                }}
              />

              <Box
                sx={{
                  position: 'absolute',
                  width: '5px',
                  height: '5px',
                  transform: 'rotate(-45deg)',
                  border: 'none',
                  borderLeft: '1px #3B3B3F solid',
                  borderTop: '1px #3B3B3F solid',
                  left: '-3px',
                  top: '-3px'
                }}
              />

              <Box
                position="relative"
                sx={{
                  height: '20px',
                  borderLeft: '1px dashed #3B3B3F',
                  top: 0,
                  left: '50%',
                  transform: 'translate(-50%, 0%)',
                  position: 'absolute'
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    width: '5px',
                    height: '5px',
                    transform: 'rotate(45deg)',
                    border: 'none',
                    borderRight: '1px #3B3B3F solid',
                    borderBottom: '1px #3B3B3F solid',
                    bottom: '-3px',
                    left: '-3px'
                  }}
                />
              </Box>
            </Box>
          </AnimatedBox>

          <AnimatedBox
            animate={true}
            fadeIn={animate.find(({ id }) => id == 4)?.fadeIn || false}
            fadeOut={animate.find(({ id }) => id == 4)?.fadeOut || false}
            animationIndex={0}
            textAlign="center"
          >
            <Image
              src={require('../../assets/tii/acquirer_psp_3.png')}
              alt=""
              disabledEffect
              sx={{ mb: 1 }}
            />

            <Box
              component="p"
              sx={{
                mt: 0,
                mb: 1,
                fontSize: '18px',
                fontWeight: 400,
                lineHeight: '32px',
                letterSpacing: '0.016em',
                color: '#696972'
              }}
            >
              IIT API
            </Box>

            <Connector sx={{ mt: 1, maxWidth: '60%', mx: 'auto' }} />
          </AnimatedBox>
        </Box>

        <AnimatedBox
          animate={true}
          fadeIn={animate.find(({ id }) => id == 3)?.fadeIn || false}
          fadeOut={animate.find(({ id }) => id == 3)?.fadeOut || false}
          animationIndex={0}
          textAlign="center"
        >
          <Image
            src={require('../../assets/tii/acquirer_psp_4.png')}
            alt=""
            disabledEffect
            sx={{ mb: 1 }}
          />

          <Box
            component="p"
            sx={{
              mt: 0,
              mb: 1,
              fontSize: '18px',
              fontWeight: 400,
              lineHeight: '32px',
              letterSpacing: '0.016em',
              color: '#696972'
            }}
          >
            PSP
          </Box>
          <Box
            component="p"
            sx={{
              my: 0,
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '24px',
              letterSpacing: '0.016em',
              color: '#232326'
            }}
          >
            Reduce unnecessary consumer friction from Strong Customer Authentication ​
          </Box>

          <Connector sx={{ mt: 1 }} />
        </AnimatedBox>
      </Box>

      <AnimatedBox
        animate={true}
        fadeIn={animate.find(({ id }) => id == 5)?.fadeIn || false}
        fadeOut={animate.find(({ id }) => id == 5)?.fadeOut || false}
        animationIndex={0}
        sx={{ marginTop: '30px' }}
      >
        <Box
          sx={{
            position: 'relative',
            width: '672px',
            height: '30px',
            border: '1px solid #3B3B3F',
            borderBottom: 'unset',
            marginRight: '160px',
            marginLeft: 'auto'
          }}
        >
          <Box
            sx={{
              height: '102px',
              width: '1px',
              position: 'absolute',
              left: '55%',
              top: '-71.5px',
              transform: 'translateX(-50%)',
              border: 'none',
              borderLeft: '1px solid #3B3B3F'
            }}
          ></Box>
        </Box>
      </AnimatedBox>

      <AnimatedBox
        animate={true}
        fadeIn={animate.find(({ id }) => id == 5)?.fadeIn || false}
        fadeOut={animate.find(({ id }) => id == 5)?.fadeOut || false}
        animationIndex={0}
        sx={{
          display: 'grid',
          gridTemplateColumns: '320px 320px 320px',
          gap: 2,
          justifyContent: 'right'
        }}
      >
        <Box sx={{ textAlign: 'center' }}>
          <Image
            src={require('../../assets/tii/acquirer_psp_5.png')}
            alt=""
            disabledEffect
            sx={{ mb: 1, mx: 'auto', maxWidth: '200px' }}
          />

          <Box
            component="p"
            sx={{
              mt: 0,
              mb: 1,
              fontSize: '18px',
              fontWeight: 400,
              lineHeight: '32px',
              letterSpacing: '0.016em',
              color: '#696972'
            }}
          >
            Low risk
          </Box>
          <Box
            component="p"
            sx={{
              my: 0,
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '24px',
              letterSpacing: '0.016em',
              color: '#232326'
            }}
          >
            The business chooses frictionless flow straight to authorization using the
            TRA Exemption​
          </Box>
        </Box>

        <Box sx={{ textAlign: 'center' }}>
          <Image
            src={require('../../assets/tii/acquirer_psp_6.png')}
            alt=""
            disabledEffect
            sx={{ mb: 1, mx: 'auto', maxWidth: '200px' }}
          />

          <Box
            component="p"
            sx={{
              mt: 0,
              mb: 1,
              fontSize: '18px',
              fontWeight: 400,
              lineHeight: '32px',
              letterSpacing: '0.016em',
              color: '#696972'
            }}
          >
            Elevated risk
          </Box>
          <Box
            component="p"
            sx={{
              my: 0,
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '24px',
              letterSpacing: '0.016em',
              color: '#232326'
            }}
          >
            The business rejects TRA exemption request by merchant and returns transaction to
            merchant for EMV 3DS authentication.​
          </Box>
        </Box>

        <Box sx={{ textAlign: 'center' }}>
          <Image
            src={require('../../assets/tii/acquirer_psp_7.png')}
            alt=""
            disabledEffect
            sx={{ mb: 1, mx: 'auto', maxWidth: '200px' }}
          />

          <Box
            component="p"
            sx={{
              mt: 0,
              mb: 1,
              fontSize: '18px',
              fontWeight: 400,
              lineHeight: '32px',
              letterSpacing: '0.016em',
              color: '#696972'
            }}
          >
            Substantial risk
          </Box>
          <Box
            component="p"
            sx={{
              my: 0,
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '24px',
              letterSpacing: '0.016em',
              color: '#232326'
            }}
          >
            The business rejects transaction and declines to process.​
          </Box>
        </Box>
      </AnimatedBox>

      <Image
        src={require('../../assets/tii/acquirer_psp.png')}
        alt=""
        disabledEffect
        sx={{
          maxWidth: '400px',
          position: 'absolute',
          bottom: '80px',
          left: 0
        }}
      />
    </Box>
  );
}

// ----------------------------------

interface PostAuthorizationUsecaseProps {
  onSetActive: (_active: string | null) => void;
  setRef: (funciones: { onNext: () => void; onBack: () => void }) => void;
}

function PostAuthorizationUsecase({ onSetActive, setRef }: PostAuthorizationUsecaseProps) {
  const [animate, setAnimate] = useState<
    {
      id: number;
      fadeIn: boolean;
      fadeOut: boolean;
    }[]
  >([
    { id: 1, fadeIn: false, fadeOut: false },
    { id: 2, fadeIn: false, fadeOut: false },
    { id: 3, fadeIn: false, fadeOut: false },
    { id: 4, fadeIn: false, fadeOut: false },
    { id: 5, fadeIn: false, fadeOut: false }
  ]);

  const onNext = () => {
    const index = animate.sort((a, b) => a.id - b.id).findIndex((a) => !a.fadeIn); // Find first item not faded in
    if (index !== -1) {
      const updatedItems = [...animate];
      updatedItems[index] = { ...updatedItems[index], fadeIn: true, fadeOut: false };
      setAnimate(updatedItems);
      console.log(`Next executed for item ${index + 1}`);
    }
  };

  const onBack = () => {
    const index = animate.sort((a, b) => b.id - a.id).findIndex((a) => a.fadeIn); // Find first item faded in
    if (index !== -1) {
      const updatedItems = [...animate];
      updatedItems[index] = { ...updatedItems[index], fadeOut: true, fadeIn: false };
      setAnimate(updatedItems.sort((a, b) => a.id - b.id));
      console.log(`Back executed for item ${index + 1}`);
    }
  };

  // Pasar las referencias de las funciones al componente padre
  useEffect(() => {
    setRef({ onNext, onBack });
  }, [setRef, animate]);

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      switch (event.key) {
        case 'ArrowLeft':
          onBack();
          break;
        case 'ArrowRight':
          onNext();
          break;
        default:
          break;
      }
    };

    // Agregar el event listener cuando el componente se monta
    window.addEventListener('keydown', handleKeyDown);

    // Eliminar el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [animate, onNext, onNext]);

  return (
    <Box sx={{ px: 3 }}>
      <Image
        src={require('../../assets/tii/post_authorization.png')}
        alt=""
        disabledEffect
        sx={{
          maxWidth: '300px',
          position: 'absolute',
          top: '100px',
          right: '50px'
        }}
      />

      <Box sx={{ display: 'grid', gridTemplateColumns: '90px 1fr', gap: 2 }}>
        <Button
          variant="outlined"
          startIcon={<Iconify icon="ph:arrow-left" />}
          onClick={() => onSetActive(null)}
        >
          Back
        </Button>

        <Box
          component="span"
          sx={{
            fontSize: '24px',
            fontWeight: 400,
            lineHeight: '32px',
            letterSpacing: '0.016em'
          }}
        >
          Post-Authentication use case
        </Box>
      </Box>

      <Connector sx={{ my: 2, maxWidth: '33%' }} />

      <Box sx={{ display: 'grid', gridTemplateColumns: '200px 80px 200px 80px 200px ', gap: 2 }}>
        <AnimatedBox
          animate={true}
          fadeIn={animate.find(({ id }) => id == 1)?.fadeIn || false}
          fadeOut={animate.find(({ id }) => id == 1)?.fadeOut || false}
          animationIndex={0}
          textAlign="center"
        >
          <Image
            src={require('../../assets/tii/post_authorization_1.png')}
            alt=""
            disabledEffect
            sx={{ mb: 1 }}
          />

          <Box
            component="p"
            sx={{
              mt: 0,
              mb: 1,
              fontSize: '18px',
              fontWeight: 400,
              lineHeight: '32px',
              letterSpacing: '0.016em',
              color: '#696972'
            }}
          >
            Authentication Network
          </Box>
          <Box
            component="p"
            sx={{
              my: 0,
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '24px',
              letterSpacing: '0.016em',
              color: '#232326'
            }}
          >
            The acquirer has authenticated the transaction, but further review is required post
            authentication.
          </Box>

          <Connector sx={{ mt: 1 }} />
        </AnimatedBox>

        <AnimatedBox
          animate={true}
          fadeIn={animate.find(({ id }) => id == 2)?.fadeIn || false}
          fadeOut={animate.find(({ id }) => id == 2)?.fadeOut || false}
          animationIndex={0}
        >
          <Box sx={{ display: 'grid', gridTemplateColumns: '75px 5px', gap: 0, mt: 6 }}>
            <Box sx={{ mt: 0.25, border: 'none', borderTop: '1px dashed #3B3B3F' }} />
            <Box
              sx={{
                width: '5px',
                height: '5px',
                transform: 'rotate(-45deg)',
                borderLeft: 'none',
                borderTop: 'none',
                borderRight: '1px #3B3B3F solid',
                borderBottom: '1px #3B3B3F solid'
              }}
            />
          </Box>
        </AnimatedBox>

        <AnimatedBox
          animate={true}
          fadeIn={animate.find(({ id }) => id == 2)?.fadeIn || false}
          fadeOut={animate.find(({ id }) => id == 2)?.fadeOut || false}
          animationIndex={0}
          textAlign="center"
        >
          <Image
            src={require('../../assets/tii/post_authorization_2.png')}
            alt=""
            disabledEffect
            sx={{ mb: 1 }}
          />

          <Box
            component="p"
            sx={{
              mt: 0,
              mb: 1,
              fontSize: '18px',
              fontWeight: 400,
              lineHeight: '32px',
              letterSpacing: '0.016em',
              color: '#696972'
            }}
          >
            Merchant
          </Box>
          <Box
            component="p"
            sx={{
              my: 0,
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '24px',
              letterSpacing: '0.016em',
              color: '#232326'
            }}
          >
            Merchant leverages insights to improve efficacy of their post authentication risk
            decisioning.
          </Box>

          <Connector sx={{ mt: 1 }} />
        </AnimatedBox>

        <AnimatedBox
          animate={true}
          fadeIn={animate.find(({ id }) => id == 4)?.fadeIn || false}
          fadeOut={animate.find(({ id }) => id == 4)?.fadeOut || false}
          animationIndex={0}
        >
          <Box sx={{ display: 'grid', gridTemplateColumns: '75px 5px', gap: 0, mt: 6 }}>
            <Box sx={{ mt: 0.25, border: 'none', borderTop: '1px dashed #3B3B3F' }} />
            <Box
              sx={{
                width: '5px',
                height: '5px',
                transform: 'rotate(-45deg)',
                borderLeft: 'none',
                borderTop: 'none',
                borderRight: '1px #3B3B3F solid',
                borderBottom: '1px #3B3B3F solid'
              }}
            />
          </Box>
        </AnimatedBox>

        <AnimatedBox
          animate={true}
          fadeIn={animate.find(({ id }) => id == 4)?.fadeIn || false}
          fadeOut={animate.find(({ id }) => id == 4)?.fadeOut || false}
          animationIndex={0}
          textAlign="center"
        >
          <Image
            src={require('../../assets/tii/post_authorization_3.png')}
            alt=""
            disabledEffect
            sx={{ mb: 1 }}
          />

          <Box
            component="p"
            sx={{
              mt: 0,
              mb: 1,
              fontSize: '18px',
              fontWeight: 400,
              lineHeight: '32px',
              letterSpacing: '0.016em',
              color: '#696972'
            }}
          >
            Risk Decisioning
          </Box>
          <Box
            component="p"
            sx={{
              my: 0,
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '24px',
              letterSpacing: '0.016em',
              color: '#232326'
            }}
          >
            The Merchant leverages the insights for risk decisioning to determine how to proceed
            with transaction.
          </Box>

          <Connector sx={{ mt: 1 }} />
        </AnimatedBox>
      </Box>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '200px 80px 200px 632px',
          gap: 2
        }}
      >
        <Box />

        <Box />

        <AnimatedBox
          animate={true}
          fadeIn={animate.find(({ id }) => id == 3)?.fadeIn || false}
          fadeOut={animate.find(({ id }) => id == 3)?.fadeOut || false}
          animationIndex={0}
          sx={{ position: 'relative', my: 2, height: '70px' }}
        >
          <Box
            position="relative"
            sx={{
              height: '70px',
              borderLeft: '1px dashed #3B3B3F',
              top: 0,
              left: '50%',
              transform: 'translate(-50%, 0%)',
              position: 'absolute'
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                width: '5px',
                height: '5px',
                transform: 'rotate(45deg)',
                border: 'none',
                borderLeft: '1px #3B3B3F solid',
                borderTop: '1px #3B3B3F solid',
                top: '-3px',
                left: '-3px'
              }}
            />

            <Box
              sx={{
                position: 'absolute',
                width: '5px',
                height: '5px',
                transform: 'rotate(45deg)',
                border: 'none',
                borderRight: '1px #3B3B3F solid',
                borderBottom: '1px #3B3B3F solid',
                bottom: '-3px',
                left: '-3px'
              }}
            />
          </Box>
        </AnimatedBox>

        <AnimatedBox
          animate={true}
          fadeIn={animate.find(({ id }) => id == 5)?.fadeIn || false}
          fadeOut={animate.find(({ id }) => id == 5)?.fadeOut || false}
          animationIndex={0}
          sx={{ position: 'relative', my: 2, height: '70px' }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: '446px',
              height: '30px',
              border: '1px solid #3B3B3F',
              borderBottom: 'unset',
              transform: 'translateX(-50%)',
              left: '50%',
              bottom: 0
            }}
          >
            <Box
              sx={{
                height: '60px',
                width: '115px',
                position: 'absolute',
                left: '160px',
                transform: 'translate(-50%, -50%)',
                border: '1px solid #3B3B3F',
                borderBottom: 'unset',
                borderLeft: 'unset'
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  height: '30px',
                  width: '1px',
                  left: 0,
                  backgroundColor: '#3B3B3F',
                  top: '-30px'
                }}
              ></Box>
            </Box>
          </Box>
        </AnimatedBox>
      </Box>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '200px 80px 200px 200px 200px 200px',
          gap: 2
        }}
      >
        <Box />

        <Box />

        <AnimatedBox
          animate={true}
          fadeIn={animate.find(({ id }) => id == 3)?.fadeIn || false}
          fadeOut={animate.find(({ id }) => id == 3)?.fadeOut || false}
          animationIndex={0}
          textAlign="center"
        >
          <Image
            src={require('../../assets/tii/post_authorization_4.png')}
            alt=""
            disabledEffect
            sx={{ mb: 1, maxWidth: 190, mx: 'auto' }}
          />

          <Box
            component="p"
            sx={{
              mt: 0,
              mb: 1,
              fontSize: '18px',
              fontWeight: 400,
              lineHeight: '32px',
              letterSpacing: '0.016em',
              color: '#696972'
            }}
          >
            IIT API
          </Box>

          <Connector sx={{ mt: 1, maxWidth: '60%', mx: 'auto' }} />
        </AnimatedBox>

        <AnimatedBox
          animate={true}
          fadeIn={animate.find(({ id }) => id == 5)?.fadeIn || false}
          fadeOut={animate.find(({ id }) => id == 5)?.fadeOut || false}
          animationIndex={0}
          textAlign="center"
        >
          <Image
            src={require('../../assets/tii/post_authorization_5.png')}
            alt=""
            disabledEffect
            sx={{ mb: 1 }}
          />

          <Box
            component="p"
            sx={{
              mt: 0,
              mb: 1,
              fontSize: '18px',
              fontWeight: 400,
              lineHeight: '32px',
              letterSpacing: '0.016em',
              color: '#696972'
            }}
          >
            Low risk
          </Box>
          <Box
            component="p"
            sx={{
              my: 0,
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '24px',
              letterSpacing: '0.016em',
              color: '#232326'
            }}
          >
            Submit transaction for settlement/proceed with shipment of merchandise
          </Box>
        </AnimatedBox>

        <AnimatedBox
          animate={true}
          fadeIn={animate.find(({ id }) => id == 5)?.fadeIn || false}
          fadeOut={animate.find(({ id }) => id == 5)?.fadeOut || false}
          animationIndex={0}
          textAlign="center"
        >
          <Image
            src={require('../../assets/tii/post_authorization_6.png')}
            alt=""
            disabledEffect
            sx={{ mb: 1 }}
          />

          <Box
            component="p"
            sx={{
              mt: 0,
              mb: 1,
              fontSize: '18px',
              fontWeight: 400,
              lineHeight: '32px',
              letterSpacing: '0.016em',
              color: '#696972'
            }}
          >
            Elevated risk
          </Box>
          <Box
            component="p"
            sx={{
              my: 0,
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '24px',
              letterSpacing: '0.016em',
              color: '#232326'
            }}
          >
            Proceeds to manually review transaction to ensure it's not fraudulent
          </Box>
        </AnimatedBox>

        <AnimatedBox
          animate={true}
          fadeIn={animate.find(({ id }) => id == 5)?.fadeIn || false}
          fadeOut={animate.find(({ id }) => id == 5)?.fadeOut || false}
          animationIndex={0}
          textAlign="center"
        >
          <Image
            src={require('../../assets/tii/post_authorization_7.png')}
            alt=""
            disabledEffect
            sx={{ mb: 1 }}
          />

          <Box
            component="p"
            sx={{
              mt: 0,
              mb: 1,
              fontSize: '18px',
              fontWeight: 400,
              lineHeight: '32px',
              letterSpacing: '0.016em',
              color: '#696972'
            }}
          >
            Substantial risk
          </Box>
          <Box
            component="p"
            sx={{
              my: 0,
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '24px',
              letterSpacing: '0.016em',
              color: '#232326'
            }}
          >
            Rejects the transactions and declines to deliver the product
          </Box>
        </AnimatedBox>
      </Box>
    </Box>
  );
}
