const ObjetoLenguage = {
    'en': {
        'general.back': 'Back',
        'general.proceed-checkout' : 'Proceed to checkout',
        'general.hi' : 'Hi',

        'home.texto-1': 'Women',
        'home.texto-2': 'Men',
        'home.texto-3': 'Accessories',
        'home.texto-4': 'NEW COLLECTION',
        'home.texto-5': "Discover what's new on the 2022 collection",
        'home.texto-6': 'See more',
        'home.texto-7': 'Women - New Arrivals',
        'home.texto-8': 'Men - New Arrivals',
        'home.texto-9': 'LIFESTYLE',
        'home.texto-10': 'New this week',
        'home.texto-11': 'Minimal collection',
        'home.texto-12': 'OVERLAP X ATHLETIC SNEAKERS',
        'home.texto-13': 'More About OVERLAP',
        'home.texto-14': 'Free shipping is valid on orders of $100 or more, after promotions and discounts are applied.',
        'home.texto-15': 'SALE',
        'home.texto-16': 'View All',
        'home.texto-17': 'Search for products or brands...',

        'footer.texto-1': 'Shop by category',
        'footer.texto-2': 'Women',
        'footer.texto-3': 'Men',
        'footer.texto-4': 'Accessories',
        'footer.texto-5': 'SALE',
        'footer.texto-6': 'About',
        'footer.texto-7': 'Contact Us',
        'footer.texto-8': 'Careers',
        'footer.texto-9': 'Press',
        'footer.texto-10': 'Policy',
        'footer.texto-11': 'Return Policy',
        'footer.texto-12': 'Terms of Use',
        'footer.texto-13': 'Sitemap',
        'footer.texto-14': 'Security',
        'footer.texto-15': 'Privacy',
        'footer.texto-16': 'EPR',
        'footer.texto-17': 'Compliance',
        'footer.texto-18': 'United States',
        'footer.texto-19': 'OVERLAP All Rights Reserved',
        'footer.texto-20': 'About us',

        'catalogue.texto-1': 'Free shipping is valid on orders of $100 or more, after promotions and discounts are applied.',
        'catalogue.texto-2': 'HOME',
        'catalogue.texto-3': 'WOMEN',
        'catalogue.texto-4': 'MEN',
        'catalogue.texto-5': 'Size',
        'catalogue.texto-6': 'Color',
        'catalogue.texto-7': 'Blue',
        'catalogue.texto-8': 'White',
        'catalogue.texto-9': 'Black',
        'catalogue.texto-10': 'Pink',
        'catalogue.texto-11': 'Yellow',
        'catalogue.texto-12': 'Multicolor',
        'catalogue.texto-13': 'Beige',
        'catalogue.texto-14': 'Price Range',
        'catalogue.texto-15': 'Discount',
        'catalogue.texto-16': 'Availability',
        'catalogue.texto-17': 'Showing 1- 40 of 145 items',
        'catalogue.texto-18': 'To Show',
        'catalogue.texto-19': 'Sort By',
        'catalogue.texto-20': 'Relevance',
        'catalogue.texto-21': 'LOWER PRICE',
        'catalogue.texto-22': 'HIGHER PRICE',
        'catalogue.texto-23': 'BEST SELLERS',
        'catalogue.texto-24': 'TOP RATED',
        'catalogue.texto-25': 'RELEASE DATE',
        'catalogue.texto-26': 'BEST DISCOUNT',

        'p-details.texto-1': 'Fall Collection',
        'p-details.texto-2': 'Ratings',
        'p-details.texto-3': 'Delivery Details',
        'p-details.texto-4': 'Free shipping on orders of 100$ or more',
        'p-details.texto-5': 'Quantity',
        'p-details.texto-6': 'Add To Bag',
        'p-details.texto-7': 'Add To Wishlist',
        'p-details.texto-8': 'Product Description',
        'p-details.texto-9': 'Ratings And Reviews',
        'p-details.texto-10': 'You Might Also Like',
        'p-details.texto-11': 'Product added!',
        'p-details.texto-12' : 'Average ratings',
        'p-details.texto-13' : '43 Ratings & 23 Reviews',

        'p-women.texto-1': 'Wool pants',
        'p-women.texto-2': 'Pants made of 100% wool. High waisted with side pockets and back welt pockets. Front zip, metal hooks, and interior button closure.',
        'p-women.texto-3': 'Soft dart pants',
        'p-women.texto-4': 'High-waisted pants with elastic waistband. Pleat detail. Soft feel fabric.',
        'p-women.texto-5': 'Printed tulle pants',
        'p-women.texto-6': 'High-waisted pants with elastic waistband and tie. Inner shorts-style lining.',
        'p-women.texto-7': 'Ribbed t-shirt',
        'p-women.texto-8': 'Round collar shirt with V-neck front, long sleeves, and vented cuffs. Contrasting seam detail.',
        'p-women.texto-9': 'Ribbed type polo',
        'p-women.texto-10': 'Fitted polo shirt with lapel collar and short sleeves. Front metal hook closure.',
        'p-women.texto-11': "Women's striped short-sleeved T-shirt",
        'p-women.texto-12': 'Regular cut in striped knit fabric, round neck and short sleeves.',
        'p-women.texto-13': 'Women midi dress',
        'p-women.texto-14': 'Dress with A cut in semi-transparent knit fabric, multicolored flowers, V neck, long sleeves, ribbon to tie at the waist and matching bottom.',
        'p-women.texto-15': 'Long floral dress Woman',
        'p-women.texto-16': 'Long cut A dress in knitted fabric, floral design, half-breasted and ruffle at the front.',
        'p-women.texto-17': 'Short striped dress Woman',
        'p-women.texto-18': 'Straight-cut dress in knitted fabric with a multicolour striped design, V neckline and short sleeves.',
        'p-women.texto-19': 'Phase eight puffer jacket',
        'p-women.texto-20': 'Straight cut in knitted fabric with a hood, fur-like interior, matching strip at the waist, pockets and zip fastening.',
        'p-women.texto-21': 'Long jacket with hood Woman',
        'p-women.texto-22': 'Coome long jacket with a straight cut in flat quilted fabric, a high neck with a hood, an adjustable drawstring, long sleeves and side pockets',
        'p-women.texto-23': "Danika women's puffer jacket",
        'p-women.texto-24': 'Functional yet feminine, insulating yet lightweight, the Danika Longline Jacket is the perfect layering piece.',
        'p-women.texto-25': 'Jeans Skinny Women',
        'p-women.texto-26': 'Skinny fit jeans, faded design',
        'p-women.texto-27': 'Straight Jeans Woman',
        'p-women.texto-28': 'Straight-cut jeans with a washed design and sequin-like appliqué on the front.',
        'p-women.texto-29': "Women's Straight-cut Jeans",
        'p-women.texto-30': 'Straight-cut jeans with a faded design and contrast stitching.',

        'p-men.texto-1': 'Topstitched cargo pants',
        'p-men.texto-2': 'Straight fit pants. Front pockets and back patch pockets. Flap patch pockets at legs. Contrasting topstitching all over the garment. Front zip and button closure.',
        'p-men.texto-3': 'Tapered fit pants. Front pleats at waist. Front pockets and back welt pockets. Front zip and button closure.',
        'p-men.texto-4': 'Pleated flannel pants',
        'p-men.texto-5': 'Slim fit pants made of wool fabric. Front pleats at waist. Front pockets and back double welt pockets. Front zip and button closure.',
        'p-men.texto-6': "Men's T-shirt",
        'p-men.texto-7': 'Relaxed cut in knit fabric.',
        'p-men.texto-8': 'Mock neck t-shirt',
        'p-men.texto-9': 'High collar T-shirt with long sleeves.',
        'p-men.texto-10': 'Tie dye print t-shirt',
        'p-men.texto-11': '"Full cut T-shirt with crew neck and short sleeves.',
        'p-men.texto-12': 'Three-quarter buttoned coat',
        'p-men.texto-13': 'Three-quarter length straight-cut coat in navy blue knit fabric with a lapel collar, piping on the sides, matching buttons and a slit at the back.',
        'p-men.texto-14': 'Cedro three-quarter buttoned coat Man',
        'p-men.texto-15': 'Three-quarter length straight-cut coat in flecked knit fabric, lapel collar, matching buttons and side pockets',
        'p-men.texto-16': 'Buttoned coat Man',
        'p-men.texto-17': 'Regular fit three-quarter coat in flat fabric, lapel collar, matching buttons and long sleeves.',
        'p-men.texto-18': "Men's leather jacket",
        'p-men.texto-19': 'Slim fit leather jacket with front closure and side pockets. With minimalist details on the body and arms.',
        'p-men.texto-20': "Men's Leather Bomber Jacket",
        'p-men.texto-21': 'Straight-cut bomber-style jacket in soft leather, Porsche logo embossed on the chest, front closure, metal button on the neck and side pockets.',
        'p-men.texto-22': "Men's Biker Jacket",
        'p-men.texto-23': 'Straight-cut leather biker jacket with double-breasted closure and pockets.',
        'p-men.texto-24': "Men's Straight Fit Jeans",
        'p-men.texto-25': 'Straight-cut jeans, faded design, fly closure, details with decorative wear and metal rivets.',
        'p-men.texto-26': "Men's Slim-fit Jeans",
        'p-men.texto-27': 'Slim cut made of denim, subtle faded design and fly closure.',
        'p-men.texto-28': 'Men Skinny Jeans',
        'p-men.texto-29': 'Skinny cut crafted in denim, subtle faded design and fly closure.',
        'p-men.texto-30': 'Pleated pants',

        'register.texto-1': 'Sign Up',
        'register.texto-2': 'Full name',
        'register.texto-3': 'Please enter your user ID',
        'register.texto-4': 'Incorrect user ID. Try alphanumeric characters',
        'register.texto-5': 'Email',
        'register.texto-6': 'Please enter your user ID',
        'register.texto-7': 'Password',
        'register.texto-8': 'Please enter your password',
        'register.texto-9': 'Short passwords are easy to guess. Try one with at least 10 alphanumeric characters',
        'register.texto-10': 'Confirm password',
        'register.texto-11': 'Your passwords didn´t match. Try again',
        'register.texto-12': 'Already have an account?',
        'register.texto-13': 'YOUR ACCOUNT HAS BEEN ACTIVATED',

        'login.texto-1': 'Log In',
        'login.texto-2': 'Email',
        'login.texto-3': 'Please enter your user ID',
        'login.texto-4': 'Incorrect user ID',
        'login.texto-5': 'Password',
        'login.texto-6': 'Incorrect password',
        'login.texto-7': 'Forgot your password?',
        'login.texto-8': "Doesn´t have an account?",
        'login.texto-9': 'Register here',

        'information.texto-1': 'Log Out',
        'information.texto-2': 'Personal Information',
        'information.texto-3': 'Upload ',
        'information.texto-4': 'Delete',
        'information.texto-5': 'Full name',
        'information.texto-6': 'Username is required',
        'information.texto-7': 'Email',
        'information.texto-8': 'Email is required',
        'information.texto-9': 'Mobile number',
        'information.texto-10': 'Date of Birth',
        'information.texto-11': 'Save Changes',
        'information.texto-12': 'Current Password',
        'information.texto-13': 'Please enter your password',
        'information.texto-14': 'Incorrect password',
        'information.texto-15': 'New Password',
        'information.texto-16': 'Please enter your new password',
        'information.texto-17': 'Short passwords are easy to guess. Try one with at least 10 alphanumeric characters',
        'information.texto-18': 'Confirm Password',
        'information.texto-19': 'Your passwords didn´t match. Try again',
        'information.texto-20': 'My Orders',
        'information.texto-21': 'Rewards',
        'information.texto-22': 'My Wishlist',
        'information.texto-23': 'My Reviews',
        'information.texto-24': 'My Address Book',
        'information.texto-25': 'My Saved Cards',

        'otp.texto-1': 'Confirm Code',
        'otp.texto-2': 'We sent a code to your mobile phone',
        'otp.texto-3': 'Enter it below.',
        'otp.texto-4': 'Didn’t get the code?',
        'otp.texto-5': 'RESEND',
        'otp.texto-6': 'CONTINUE',

        'bag.texto-1': 'You havent´t added any items yet',
        'bag.texto-2': 'Click here to start shopping!',
        'bag.texto-3': 'My Bag',
        'bag.texto-4': 'Product Name',
        'bag.texto-5': 'Price',
        'bag.texto-6': 'Qty',
        'bag.texto-7': 'Subtotal',
        'bag.texto-8': 'Move to Wishlist',
        'bag.texto-9': 'Order Summary',
        'bag.texto-10': 'Discount',
        'bag.texto-11': 'Delivery Fee',
        'bag.texto-12': 'Grand Total',
        'bag.texto-13': 'Place Order',
        'bag.texto-14': 'Continue Shopping',
        'bag.texto-15': 'Remove',
        'bag.texto-16' : 'Coupon Applied!',
        'bag.texto-17': 'Click here',
        'bag.texto-18' : 'to start shopping!',

        'checkout.texto-1': 'Checkout',
        'checkout.texto-2': 'Registered users might have benefits and special discounts!',
        'checkout.texto-3': 'Log in/Sign up',
        'checkout.texto-4': 'Buy as a guest',
        'checkout.texto-5': 'Back to Bag',
        'checkout.texto-6': 'Order Summary',
        'checkout.texto-7': 'Apply Coupon Code',
        'checkout.texto-8': 'CHECK',
        'checkout.texto-9': 'Order Details',
        'checkout.texto-10': 'Subtotal',
        'checkout.texto-11': 'Discount',
        'checkout.texto-12': 'Delivery Fee',
        'checkout.texto-13': 'Invalid Coupon!',
        'checkout.texto-14': 'Invalid Coupon!',
        'checkout.texto-15': 'Next',
        'checkout.texto-16': 'Coupon Applied!',
        'checkout.texto-17': 'I agree to',
        'checkout.texto-18': 'terms and conditions',
        'checkout.texto-19': 'and privacy policy.',
        'checkout.texto-20': 'Confirm Order',

        'shipping.texto-1': 'Shipping Address',
        'shipping.texto-2': 'Full Name',
        'shipping.texto-3': 'Please enter your name',
        'shipping.texto-4': 'Mobile Number',
        'shipping.texto-5': 'Please enter your phone number',
        'shipping.texto-6': 'Enter 10 digits',
        'shipping.texto-7': 'Street Address',
        'shipping.texto-8': 'Please enter your street address',
        'shipping.texto-9': 'Address details',
        'shipping.texto-10': 'Please enter your address details',
        'shipping.texto-11': 'ZIP Code',
        'shipping.texto-12': 'Please enter your ZIP Code',
        'shipping.texto-13': 'City',
        'shipping.texto-14': 'Please enter your city',
        'shipping.texto-15': 'State',
        'shipping.country': 'Country',
        'shipping.texto-16': 'Please enter your state',
        'shipping.texto-17': 'Order notes(optional)',
        'shipping.texto-18': 'Add New Address',
        'shipping.texto-19': 'Back',
        'shipping.texto-20': 'Next',
        'shipping.texto-21': 'Order Summary',
        'shipping.texto-22': 'Apply Coupon Code',
        'shipping.texto-23': 'CHECK',
        'shipping.texto-24': 'Order Details',
        'shipping.texto-25': 'Sub Total',
        'shipping.texto-26': 'Discount',
        'shipping.texto-27': 'Delivery Fee',
        'shipping.texto-28': 'Grand Total',
        'shipping.texto-29': 'Invalid Coupon!',
        'shipping.texto-30': 'Save address',

        'billing.texto-1': 'Billing Address',
        'billing.texto-2': 'My billing and shipping address are the same',
        'billing.texto-3': 'Full Name',
        'billing.texto-4': 'Please enter your name',
        'billing.texto-5': 'Mobile Number',
        'billing.texto-6': 'Please enter your phone number',
        'billing.texto-7': 'Street Address',
        'billing.texto-8': 'Please enter your street address',
        'billing.texto-9': 'Address details',
        'billing.email': 'Email',
        'billing.texto-10': 'Please enter your address details',
        'billing.texto-11': 'ZIP Code',
        'billing.texto-12': 'Please enter your ZIP Code',
        'billing.texto-13': 'City',
        'billing.texto-14': 'Please enter your city',
        'billing.texto-15': 'State',
        'billing.country': 'Country',
        'billing.texto-16': 'Please enter your state',
        'billing.texto-17': 'Back',
        'billing.texto-18': 'Next',
        'billing.texto-19': 'Order Summary',
        'billing.texto-20': 'Apply Coupon Code',
        'billing.texto-21': 'CHECK',
        'billing.texto-22': 'Order Details',
        'billing.texto-23': 'Sub Total',
        'billing.texto-24': 'Discount',
        'billing.texto-25': 'Delivery Fee',
        'billing.texto-26': 'Grand Total',
        'billing.texto-27': 'Invalid Coupon!',

        'payment.texto-1': 'Payment method',
        'payment.texto-2': 'Credit/Debit Card',
        'payment.texto-3': 'Gift card',
        'payment.texto-4': 'Card number',
        'payment.texto-5': 'Name on card',
        'payment.texto-6': 'Expiry Date',
        'payment.texto-7': 'Security code(CVV)',
        'payment.texto-8': 'Saved cards',
        'payment.texto-9': 'ending in',
        'payment.texto-10': 'Add A New Card',
        'payment.texto-11': 'Your gift card balance',
        'payment.texto-12': 'Use available card balance',
        'payment.texto-13': 'Reedem Gift Card',
        'payment.texto-14': 'View balance history',
        'payment.texto-15': 'Rewards',
        'payment.texto-16': 'Use available rewards cash ',
        'payment.texto-17': 'How are rewards earned?',
        'payment.texto-18': 'Back to Billing address',
        'payment.texto-19': 'Next',
        'payment.texto-20': 'Order Summary',
        'payment.texto-21': 'Apply Coupon Code',
        'payment.texto-22': 'CHECK',
        'payment.texto-23': 'Order Details',
        'payment.texto-24': 'Sub Total',
        'payment.texto-25': 'Discount',
        'payment.texto-26': 'Delivery Fee',
        'payment.texto-27': 'Grand Total',
        'payment.texto-28': 'Invalid Coupon!',
        'payment.texto-29': 'I agree to terms and conditions and privacy policy.',
        'payment.texto-30': 'Confirm Order',
        'payment.texto-31': 'Enter a valid code.',

        'add_card.texto-1': 'Add new Card',
        'add_card.texto-2': 'Cardholder Name',
        'add_card.texto-3': 'Please enter your card name',
        'add_card.texto-4': 'Card Number',
        'add_card.texto-5': 'Enter a 16 digit card number',
        'add_card.texto-6': 'Expiry Date',
        'add_card.texto-7': 'Enter a valid date',
        'add_card.texto-8': 'CVC',
        'add_card.texto-9': 'Please enter your cvv',
        'add_card.texto-10': 'enter a 3 digit cvv',
        'add_card.texto-11': 'Cancel',
        'add_card.texto-12': 'Add Card',
        'add_card.texto-13': 'Reedem gift card applied!',

        'reedem_card.texto-1': 'Reedem gift card',
        'reedem_card.texto-2': 'Gift card number (dashes not required)',
        'reedem_card.texto-3': 'How do i find the claim code?',
        'reedem_card.texto-4': 'Cancel',
        'reedem_card.texto-5': 'Apply To Your Balance',
        'reedem_card.texto-6': 'Reedem gift card applied!',

        'order_placed.texto-1': 'Order #211 placed',
        'order_placed.texto-2': 'Delivery estimated:',
        'order_placed.texto-3': 'Order Summary',
        'order_placed.texto-4': 'Shipping Address',
        'order_placed.texto-5': 'Delivery notes: ',
        'order_placed.texto-6': 'Billing address',
        'order_placed.texto-7': 'Order Details',
        'order_placed.texto-8': 'Track My Order',
        'order_placed.texto-9': 'Discount',
        'order_placed.texto-10': 'Delivery Fee',
        'order_placed.texto-11': 'Grand Total',
        'order_placed.texto-12': 'Rewards',
        'order_placed.texto-13': 'With this purchase you obtained 10 points that later can be used as money!',
        'order_placed.texto-14': 'ending in',
        'order_placed.texto-15': 'Gift card balance',
        'order_placed.texto-16': 'Continue Shopping'
    },
    'es': {
        'general.back': 'Regresar',
        'general.proceed-checkout' : 'Proceder al pago',
        'general.hi' : 'Hola',

        'home.texto-1': 'Mujeres',
        'home.texto-2': 'Hombres',
        'home.texto-3': 'Accesorios',
        'home.texto-4': 'NUEVA COLECCIÓN',
        'home.texto-5': "Descubre lo más reciente de la colección de 2022",
        'home.texto-6': 'Ver más',
        'home.texto-7': 'Mujeres - Novedades',
        'home.texto-8': 'Hombres - Novedades',
        'home.texto-9': 'ESTILO DE VIDA',
        'home.texto-10': 'Nuevo esta semana',
        'home.texto-11': 'Colección minimalista',
        'home.texto-12': 'OVERLAP X ATHLETIC SNEAKERS',
        'home.texto-13': 'Más Acerca de OVERLAP',
        'home.texto-14': 'El envío gratis es válido en pedidos de $100 o más, después de que las promociones y descuentos sean aplicados',
        'home.texto-15': 'DESCUENTOS',
        'home.texto-16': 'Ver Todo',
        'home.texto-17': 'Buscar por producto o marca...',

        'footer.texto-1': 'Compra por categoría',
        'footer.texto-2': 'Mujeres',
        'footer.texto-3': 'Hombres',
        'footer.texto-4': 'Accesorios',
        'footer.texto-5': 'DESCUENTOS',
        'footer.texto-6': 'Acerca',
        'footer.texto-7': 'Contáctanos',
        'footer.texto-8': 'Carreras',
        'footer.texto-9': 'Prensa',
        'footer.texto-10': 'Política ',
        'footer.texto-11': 'Póliza de Reembolso',
        'footer.texto-12': 'Términos de Uso',
        'footer.texto-13': 'Mapa del sitio',
        'footer.texto-14': 'Seguridad',
        'footer.texto-15': 'Privacidad',
        'footer.texto-16': 'EPR',
        'footer.texto-17': 'Cumplimiento Normativo',
        'footer.texto-18': 'Estados Unidos',
        'footer.texto-19': 'OVERLAP Todos los Derechos Reservados',
        'footer.texto-20': 'Acerca de nosotros',

        'catalogue.texto-1': 'El envío gratis es válido en pedidos de $100 o más, después de que las promociones y descuentos sean aplicados.',
        'catalogue.texto-2': 'INICIO',
        'catalogue.texto-3': 'MUJERES',
        'catalogue.texto-4': 'HOMBRES',
        'catalogue.texto-5': 'Talla',
        'catalogue.texto-6': 'Color',
        'catalogue.texto-7': 'Azul',
        'catalogue.texto-8': 'Blanco',
        'catalogue.texto-9': 'Negro',
        'catalogue.texto-10': 'Rosa',
        'catalogue.texto-11': 'Amarillo',
        'catalogue.texto-12': 'Multicolor',
        'catalogue.texto-13': 'Beige',
        'catalogue.texto-14': 'Rango de Precio',
        'catalogue.texto-15': 'Descuento',
        'catalogue.texto-16': 'Disponibilidad',
        'catalogue.texto-17': 'Mostrando 1- 40 de 145 artículos',
        'catalogue.texto-18': 'Para mostrar',
        'catalogue.texto-19': 'Ordenar por',
        'catalogue.texto-20': 'Relevancia',
        'catalogue.texto-21': 'MENOR PRECIO',
        'catalogue.texto-22': 'MAYOR PRECIO',
        'catalogue.texto-23': 'MÁS VENDIDOS',
        'catalogue.texto-24': 'MEJOR VALORADO',
        'catalogue.texto-25': 'FECHA DE LANZAMIENTO',
        'catalogue.texto-26': 'MEJOR DESCUENTO',

        'p-details.texto-1': 'Colección de Otoño',
        'p-details.texto-2': 'Valoraciones',
        'p-details.texto-3': 'Detalles de Envío',
        'p-details.texto-4': 'Envío gratis en pedidos de $100 o más',
        'p-details.texto-5': 'Cantidad',
        'p-details.texto-6': 'Añadir a la bolsa',
        'p-details.texto-7': 'Añadir a la lista de deseados',
        'p-details.texto-8': 'Descripción de productos',
        'p-details.texto-9': 'Valoraciones y Reseñas',
        'p-details.texto-10': 'Podría Gustarte ',
        'p-details.texto-11': 'Producto añadido!',
        'p-details.texto-12' : 'Valoración promedio',
        'p-details.texto-13' : '43 Valoraciones & 23 Reseñas',

        'p-women.texto-1': 'Pantalón de lana',
        'p-women.texto-2': 'Pantalón hecho 100% de lana. De cintura alta con bolsillos laterales y bolsillos traseros. Cierre con cremallera frontal, corchetes metálicos y botón interior.',
        'p-women.texto-3': 'Pantalón de pinzas suaves',
        'p-women.texto-4': 'Pantalón de tiro alto con cinturilla elástica. Detalle de pliegues. Tejido de tacto suave.',
        'p-women.texto-5': 'Pantalón tul estampados',
        'p-women.texto-6': 'Pantalón de cintura alta con cinturilla elástica y lazo. Forro interior tipo pantalón corto.',
        'p-women.texto-7': 'Camista acanalada',
        'p-women.texto-8': 'Camisa de cuello redondo con escote de pico en la parte delantera, manga larga y puños con abertura. Detalle de costura a contraste.',
        'p-women.texto-9': 'Polo tipo canalé',
        'p-women.texto-10': 'Polo entallado con cuello solapa y manga corta. Cierre frontal de gancho metálico.',
        'p-women.texto-11': "Camiseta de manga corta a rayas para mujer",
        'p-women.texto-12': 'Corte regular en tejido de punto a rayas, cuello redondo y manga corta.',
        'p-women.texto-13': 'Vestido midi de mujer',
        'p-women.texto-14': 'Vestido de corte A en tejido de punto semitransparente, flores multicolor, escote en pico, manga larga, lazo para anudar en la cintura y bajo a tono.',
        'p-women.texto-15': 'Vestido largo de flores para mujer',
        'p-women.texto-16': 'Vestido de corte largo A en tejido de punto, diseño floral, con media botonadura y volante en la parte delantera.',
        'p-women.texto-17': 'Vestido corto a rayas para mujer',
        'p-women.texto-18': 'Vestido de corte recto en tejido de punto con diseño de rayas multicolor, escote en pico y manga corta.',
        'p-women.texto-19': 'Chaqueta puffer Phase eight',
        'p-women.texto-20': 'Corte recto en tejido de punto con capucha, interior de pelo, tira a juego en la cintura, bolsillos y cierre de cremallera.',
        'p-women.texto-21': 'Chaqueta larga con capucha para Mujer',
        'p-women.texto-22': 'Chaqueta larga de corte recto en tejido plano acolchado, cuello alto con capucha, cordón ajustable, mangas largas y bolsillos laterales.',
        'p-women.texto-23': "Chaqueta Danika para mujer",
        'p-women.texto-24': 'Funcional pero femenina, aislante pero ligera, la chaqueta Danika Longline es la prenda perfecta para vestir a capas.',
        'p-women.texto-25': 'Vaqueros corte Skinny para Mujer',
        'p-women.texto-26': 'Vaqueros corte skinny, diseño desteñido',
        'p-women.texto-27': 'Vaqueros rectos para Mujer',
        'p-women.texto-28': 'Vaqueros de corte recto con diseño lavado y aplicado de lentejuelas en la parte delantera.',
        'p-women.texto-29': "Vaqueros de corte recto para mujer",
        'p-women.texto-30': 'Vaqueros de corte recto con un diseño desteñido y costuras en contraste.',

        'p-men.texto-1': 'Pantalón cargo con pespuntes',
        'p-men.texto-2': 'Pantalón de corte recto. Bolsillos delanteros y bolsillos traseros de parche. Bolsillos de parche con solapa en las piernas. Pespuntes a contraste en toda la prenda. Cierre frontal con cremallera y botón.',
        'p-men.texto-3': 'Pantalón de corte cónico. Pliegues delanteros en la cintura. Bolsillos delanteros y bolsillos traseros. Cierre frontal con cremallera y botón.',
        'p-men.texto-4': 'Pantalón de franela plisado',
        'p-men.texto-5': 'Pantalón slim fit confeccionado en tejido de lana. Pliegues delanteros en la cintura. Bolsillos delanteros y bolsillos traseros de doble vivo. Cierre frontal con cremallera y botón.',
        'p-men.texto-6': "Camiseta para hombre",
        'p-men.texto-7': 'Corte relajado en tejido de punto.',
        'p-men.texto-8': 'Camiseta de cuello alto',
        'p-men.texto-9': 'Camiseta de cuello alto y manga larga.',
        'p-men.texto-10': 'Camiseta con estampado tie dye',
        'p-men.texto-11': 'Camiseta de corte amplio con cuello redondo y manga corta.',
        'p-men.texto-12': 'Abrigo de tres cuartos abotonado',
        'p-men.texto-13': 'Abrigo de tres cuartos de corte recto en tejido de punto azul marino con cuello solapa, ribetado en los laterales, botones a tono y abertura en la espalda.',
        'p-men.texto-14': 'Abrigo de tres cuartos abotonado color cedro para hombre',
        'p-men.texto-15': 'Abrigo tres cuartos de corte recto en tejido de punto jaspeado, cuello solapa, botones al tono y bolsillos laterales',
        'p-men.texto-16': 'Abrigo abotonado para hombreButtoned coat Man',
        'p-men.texto-17': 'Abrigo tres cuartos regular fit en tejido plano, cuello solapa, botones a tono y manga larga.',
        'p-men.texto-18': "Chaqueta de piel para hombres",
        'p-men.texto-19': 'Chaqueta de piel slim fit con cierre frontal y bolsillos laterales. Con detalles minimalistas en cuerpo y brazos.',
        'p-men.texto-20': "Chaqueta bomber de piel para hombre",
        'p-men.texto-21': 'Chaqueta estilo bomber de corte recto en piel suave, logo Porsche grabado en el pecho, cierre frontal, botón metálico en el cuello y bolsillos laterales.',
        'p-men.texto-22': "Chaqueta de motociclista para hombres",
        'p-men.texto-23': 'Chaqueta de motociclista de piel de corte recto con cierre de doble botonadura y bolsillos.',
        'p-men.texto-24': "Vaqueros rectos para hombre",
        'p-men.texto-25': 'Vaqueros de corte recto, diseño desteñido, cierre de bragueta, detalles con desgaste decorativo y remaches metálicos.',
        'p-men.texto-26': "Vaqueros ajustados de hombre",
        'p-men.texto-27': 'Corte slim confeccionado en denim, sutil diseño desteñido y cierre de bragueta.',
        'p-men.texto-28': 'Vaqueros ajustados de hombre',
        'p-men.texto-29': 'Corte skinny confeccionado en denim, sutil diseño desteñido y cierre de bragueta.',
        'p-men.texto-30': 'Pantalón de franela plisado',

        'register.texto-1': 'Registrarse',
        'register.texto-2': 'Nombre Completo',
        'register.texto-3': 'Por favor ingresa tu ID de usuario',
        'register.texto-4': 'ID de usuario incorrecta. Intenta caractéres alfanuméricos',
        'register.texto-5': 'Correo electrónico',
        'register.texto-6': 'Por favor ingresa tu ID de usuario',
        'register.texto-7': 'Contraseña',
        'register.texto-8': 'Por favor ingresa tu contraseña',
        'register.texto-9': 'Las contraseñas cortas son fáciles de advinar. Prueba otra con al menos 10 caractéres alfanuméricos',
        'register.texto-10': 'Confirmar contraseña',
        'register.texto-11': 'Tus contaseñas no concidieron. Inténtalo de nuevo',
        'register.texto-12': '¿Ya tienes una cuenta?',
        'register.texto-13': 'TU CUENTA HA SIDO ACTIVADA',

        'login.texto-1': 'Iniciar sesión',
        'login.texto-2': 'Correo electrónico',
        'login.texto-3': 'Por favor ingresa tu ID de usuario',
        'login.texto-4': 'ID de usuario incorrecta',
        'login.texto-5': 'Contraseña',
        'login.texto-6': 'Contraseña incorrecta',
        'login.texto-7': '¿Olvidaste tu contraseña?',
        'login.texto-8': "¿No tienes una cuenta?",
        'login.texto-9': 'Regístrate aquí',

        'information.texto-1': 'Cerrar Sesión',
        'information.texto-2': 'Información Personal',
        'information.texto-3': 'Subir ',
        'information.texto-4': 'Borrar',
        'information.texto-5': 'Nombre completo',
        'information.texto-6': 'Se requiere nombre de usuario ',
        'information.texto-7': 'Correo electrónico',
        'information.texto-8': 'Correo electrónico es requerido',
        'information.texto-9': 'Número telefónico',
        'information.texto-10': 'Fecha de nacimiento',
        'information.texto-11': 'Guardar Cambios',
        'information.texto-12': 'Contraseña Actual',
        'information.texto-13': 'Por favor, ingresa tu contraseña',
        'information.texto-14': 'Contraseña incorrecta',
        'information.texto-15': 'Nueva Contraseña',
        'information.texto-16': 'Por favor, ingresa tu nueva contraseña',
        'information.texto-17': 'Las contraseñas cortas son fáciles de advinar. Prueba otra con al menos 10 caractéres alfanuméricos',
        'information.texto-18': 'Por favor, ingresa tu nueva contraseña',
        'information.texto-19': 'Tus contraseñas no coincidieron. Inténtalo de nuevo',
        'information.texto-20': 'Mis pedidos',
        'information.texto-21': 'Recompensas',
        'information.texto-22': 'Mi lista de Deseados',
        'information.texto-23': 'Mis Reseñas',
        'information.texto-24': 'Agenda de direcciones',
        'information.texto-25': 'Mis Tarjetas Guardadas',

        'otp.texto-1': 'Confirmar Código',
        'otp.texto-2': 'Te enviamos un código a tu teléfono celular',
        'otp.texto-3': 'Ingrésalo abajo',
        'otp.texto-4': '¿No recibiste el código?',
        'otp.texto-5': 'REENVIAR',
        'otp.texto-6': 'CONTINUAR',

        'bag.texto-1': 'No has añadido ningún producto aún',
        'bag.texto-2': 'Click aquí para empezar a comprar!',
        'bag.texto-3': 'Mi Bolsa',
        'bag.texto-4': 'Nombre de producto',
        'bag.texto-5': 'Precio',
        'bag.texto-6': 'Cant',
        'bag.texto-7': 'Subtotal',
        'bag.texto-8': 'Mover a la lista de deseados',
        'bag.texto-9': 'Resumen del pedido',
        'bag.texto-10': 'Descuento',
        'bag.texto-11': 'Tarifa de entrega',
        'bag.texto-12': 'Total general',
        'bag.texto-13': 'Realizar pedido',
        'bag.texto-14': 'Continuar comprando',
        'bag.texto-15': 'Remover',
        'bag.texto-16' : 'Cupón Aplicado!',
        'bag.texto-17': 'Click aquí',
        'bag.texto-18' : 'para comenzar a comprar!',

        'checkout.texto-1': 'Caja',
        'checkout.texto-2': 'Usuarios registrados tienen beneficios y descuentos especiales!',
        'checkout.texto-3': 'Iniciar sesión/Registrarse',
        'checkout.texto-4': 'Comprar como invitado',
        'checkout.texto-5': 'Volver al carrito',
        'checkout.texto-6': 'Resumen del pedido',
        'checkout.texto-7': 'Aplicar código de cupón',
        'checkout.texto-8': 'CHECAR',
        'checkout.texto-9': 'Detalle de pedido',
        'checkout.texto-10': 'Subtotal',
        'checkout.texto-11': 'Descuento',
        'checkout.texto-12': 'Tarifa de entrega',
        'checkout.texto-13': 'Cupón inválido!',
        'checkout.texto-14': 'Cupón inválido!',
        'checkout.texto-15': 'Siguiente',
        'checkout.texto-16': 'Cupón Aplicado',
        'checkout.texto-17': 'Acepto',
        'checkout.texto-18': 'términos y condiciones',
        'checkout.texto-19': 'y políticas de privacidad.',
        'checkout.texto-20': 'Confirmar Orden',

        'shipping.texto-1': 'Dirección de envío',
        'shipping.texto-2': 'Nombre completo',
        'shipping.texto-3': 'Por favor, ingresa tu nombre',
        'shipping.texto-4': 'Número telefónico',
        'shipping.texto-5': 'Por favor, ingresa tu número teléfonico',
        'shipping.texto-6': 'Ingresa 10 dígitos',
        'shipping.texto-7': 'Dirección de calle',
        'shipping.texto-8': 'Por favor, ingresa tu dirección de calle',
        'shipping.texto-9': 'Detalles de dirección',
        'shipping.texto-10': 'Por favor, ingresa tus detalles de direccion',
        'shipping.texto-11': 'Código Postal',
        'shipping.texto-12': 'Por favor, ingresa tu código postal',
        'shipping.texto-13': 'Ciudad',
        'shipping.texto-14': 'Por favor, ingresa tu ciudad',
        'shipping.texto-15': 'Estado',
        'shipping.country': 'País',
        'shipping.texto-16': 'Por favor, ingresa tu estado',
        'shipping.texto-17': 'Notas de pedido (Opcional)',
        'shipping.texto-18': 'Añadir Nueva Dirección',
        'shipping.texto-19': 'Volver',
        'shipping.texto-20': 'Siguiente',
        'shipping.texto-21': 'Resumen del pedido',
        'shipping.texto-22': 'Aplicar código de cupón',
        'shipping.texto-23': 'CHECAR',
        'shipping.texto-24': 'Detalle de pedido',
        'shipping.texto-25': 'Subtotal',
        'shipping.texto-26': 'Descuento',
        'shipping.texto-27': 'Tarifa de entrega',
        'shipping.texto-28': 'Total general',
        'shipping.texto-29': 'Cupón inválido!',
        'shipping.texto-30': 'Guardar dirección',

        'billing.texto-1': 'Dirección de Factura',
        'billing.texto-2': 'Mis direcciones de factura y envío son las mismas',
        'billing.texto-3': 'Nombre Completo',
        'billing.texto-4': 'Por favor ingresa tu nombre',
        'billing.texto-5': 'Número Celular',
        'billing.texto-6': 'Por favor ingresa tu número celular',
        'billing.texto-7': 'Dirección de Calle',
        'billing.texto-8': 'Por favor ingresa tu dirección de calle',
        'billing.texto-9': 'Detalles de dirección',
        'billing.email': 'Email',
        'billing.texto-10': 'Por favor ingresa tus detalles de dirección ',
        'billing.texto-11': 'Código Postal',
        'billing.texto-12': 'Por favor ingresa tu Código Postal',
        'billing.texto-13': 'Ciudad',
        'billing.texto-14': 'Por favor ingresa tu ciudad',
        'billing.texto-15': 'Estado',
        'billing.texto-16': 'Por favor ingresa tu estado',
        'billing.texto-17': 'Volver',
        'billing.texto-18': 'Siguiente',
        'billing.texto-19': 'Resumen del pedido',
        'billing.texto-20': 'Aplicar código de cupón',
        'billing.texto-21': 'CHECAR',
        'billing.texto-22': 'Detalle de pedido',
        'billing.texto-23': 'Subtotal',
        'billing.texto-24': 'Descuento',
        'billing.texto-25': 'Tarifa de entrega',
        'billing.texto-26': 'Total general',
        'billing.texto-27': 'Cupón inválido!',

        'payment.texto-1': 'Método de pago',
        'payment.texto-2': 'Tarjeta de Crédito/Débito',
        'payment.texto-3': 'Tarjeta de regalo',
        'payment.texto-4': 'Número de tarjeta',
        'payment.texto-5': 'Nombre en la tarjeta',
        'payment.texto-6': 'Fecha de expiración',
        'payment.texto-7': 'Código de seguridad (CVV)',
        'payment.texto-8': 'Tarjetas Guardadas',
        'payment.texto-9': 'termina en',
        'payment.texto-10': 'Añadir Una Nueva Tarjeta',
        'payment.texto-11': 'Saldo de tu tarjeta de regalo',
        'payment.texto-12': 'Usar saldo disponible en la tarjeta',
        'payment.texto-13': 'Canjear Tarjeta de Regalo',
        'payment.texto-14': 'Ver historial de saldo',
        'payment.texto-15': 'Recompensas',
        'payment.texto-16': 'Usar dinero disponible de recompensas',
        'payment.texto-17': '¿Cómo ganar recompensas?',
        'payment.texto-18': 'Volver a Dirección de factura',
        'payment.texto-19': 'Siguiente',
        'payment.texto-20': 'Resumen del pedido',
        'payment.texto-21': 'Aplicar Código de Cupón ',
        'payment.texto-22': 'CHECAR',
        'payment.texto-23': 'Detalles del pedido',
        'payment.texto-24': 'Subtotal',
        'payment.texto-25': 'Descuento',
        'payment.texto-26': 'Tarifa de Entrega',
        'payment.texto-27': 'Total General',
        'payment.texto-28': 'Cupón Inválido!',
        'payment.texto-29': 'Estoy de acuerdo con los términos y condiciones y la póliza de privacidad.',
        'payment.texto-30': 'Confirmar Pedido',
        'payment.texto-31': "Introduzca un código válido",

        'add_card.texto-1': 'Añadir nueva tarjeta',
        'add_card.texto-2': 'Nombre en la tarjeta',
        'add_card.texto-3': 'Por favor, ingresa el nombre de tu tarjeta',
        'add_card.texto-4': 'Número de la tarjeta',
        'add_card.texto-5': 'Ingresa los 16 dígitos del número de la terjeta',
        'add_card.texto-6': 'Fecha de expiración ',
        'add_card.texto-7': 'Ingresa una fecha valida',
        'add_card.texto-8': 'CVC',
        'add_card.texto-9': 'Por favor, ingresa tu cvv',
        'add_card.texto-10': 'Ingresa un cvv de 3 dígitos',
        'add_card.texto-11': 'Cancelar',
        'add_card.texto-12': 'Añadir tarjeta',
        'add_card.texto-13': 'Tarjeta de regalo aplicada!',

        'reedem_card.texto-1': 'Canjear tarjeta de regalo',
        'reedem_card.texto-2': 'Número de tarjeta de regalo (No se requieren guiones)',
        'reedem_card.texto-3': '¿Cómo encuentro el código de reclamo?',
        'reedem_card.texto-4': 'Cancelar',
        'reedem_card.texto-5': 'Aplicar a Tu Saldo',
        'reedem_card.texto-6': 'Tarjeta de regalo aplicada!',

        'order_placed.texto-1': 'Orden #211 realizada',
        'order_placed.texto-2': 'Entrega estimada:',
        'order_placed.texto-3': 'Resumen del Pedido',
        'order_placed.texto-4': 'Dirección de Envío',
        'order_placed.texto-5': 'Notas de Entrega: ',
        'order_placed.texto-6': 'Dirección de Factura',
        'order_placed.texto-7': 'Detalles del Pedido',
        'order_placed.texto-8': 'Rastrear Mi Pedido',
        'order_placed.texto-9': 'Descuento',
        'order_placed.texto-10': 'Tarifa de Entrega',
        'order_placed.texto-11': 'Total General',
        'order_placed.texto-12': 'Recompensas',
        'order_placed.texto-13': 'Con esta compra habrás obtenido 10 puntos que después pueden ser usados como dinero!',
        'order_placed.texto-14': 'termina en',
        'order_placed.texto-15': 'Saldo de Tarjeta de regalo',
        'order_placed.texto-16': 'Continúa Comprando'
    }
}

export default ObjetoLenguage as any;