import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
//
import { useSelector, useDispatch } from '../../redux/store';
import { setRiskLevelTII } from '../../redux/slices/NuDetect';
// @mui
import { Fab, Popover, Box, Button } from '@mui/material';
//
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

export default function DemoSettings() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { isOpenConsole, platform_security, riskLevelTII } = useSelector(
    (state) => state.nuDetectState
  );

  const [open, setOpen] = useState(false);
  const [risk, setRisk] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (riskLevelTII) setRisk(riskLevelTII);
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const onSetRisk = (_risk: string | null) => {
    setRisk(_risk);
  };

  const onSaveRisk = () => {
    if (risk) dispatch(setRiskLevelTII(risk));
    handleClose();
  };

  const onResetRisk = () => {
    setRisk(null);
    dispatch(setRiskLevelTII(''));
    handleClose();
    navigate('/shipping-address');
  };

  if (isOpenConsole || platform_security !== 'TransactionIdentityInsights') return null;

  return (
    <Box>
      <Fab
        color="default"
        onClick={handleOpen}
        sx={{
          backgroundColor: open ? '#232326' : '#D7DCE4',
          '&:hover': {
            backgroundColor: open ? '#37373b' : '#f5f5f5'
          },
          ...(open && {
            color: '#FFFFFF'
          })
        }}
      >
        <Iconify icon={open ? 'ic:round-close' : 'uil:setting'} />
      </Fab>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        PaperProps={{
          sx: {
            backgroundColor: 'transparent',
            boxShadow: 'unset',
            borderRadius: 'unset',
            border: 'unset'
          }
        }}
      >
        <Box
          sx={{
            minWidth: '394px',
            maxWidth: '394px',
            boxShadow: '0px 16px 16px -8px #0000003D',
            borderRadius: '8px',
            bgcolor: '#3B3B3F',
            p: 3
          }}
        >
          <Box
            component="p"
            sx={{
              fontSize: '24px',
              fontWeight: 700,
              lineHeight: '32px',
              letterSpacing: '0.016em',
              color: '#E6E6E8',
              m: 0,
              mb: 2
            }}
          >
            Demo settings
          </Box>

          <Box
            component="p"
            sx={{
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '24px',
              letterSpacing: '0.016em',
              color: '#9A9AA2',
              m: 0
            }}
          >
            Please select the desired risk level to receive for the current transaction
          </Box>

          <Box sx={{ my: 2, border: 'none', borderTop: '1px dashed #9A9AA2' }} />

          <Box
            component="p"
            sx={{
              fontSize: '14px',
              fontWeight: 700,
              lineHeight: '24px',
              letterSpacing: '0.016em',
              color: '#9A9AA2',
              m: 0,
              mb: 2
            }}
          >
            Risk level
          </Box>

          <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 1 }}>
            <Button
              variant="outlined"
              sx={{
                padding: '5px 15px!important',
                backgroundColor: '#E6E6E8!important',
                border: '2px solid #E6E6E8',
                color: '#3B3B3F',
                '&:hover': {
                  backgroundColor: '#F6F6F8!important',
                  border: '2px solid #F6F6F8'
                },
                ...(risk === 'unknown' && {
                  border: '2px solid #E6E6E8',
                  backgroundColor: '#3B3B3F!important',
                  color: '#E6E6E8',
                  '&:hover': {
                    backgroundColor: '#85858B!important',
                    border: '2px solid #E6E6E8'
                  }
                }),
                ...(!pathname.includes('shipping-address') &&
                  risk !== 'unknown' && {
                    opacity: 0.5
                  })
              }}
              onClick={() => (pathname.includes('shipping-address') ? onSetRisk('unknown') : null)}
            >
              Unknown
            </Button>

            <Button
              variant="outlined"
              sx={{
                padding: '5px 15px!important',
                backgroundColor: '#E8F4D5!important',
                border: '2px solid #E8F4D5',
                color: '#46611A',
                '&:hover': {
                  backgroundColor: '#F5FBE8!important',
                  border: '2px solid #F5FBE8'
                },
                ...(risk === 'low' && {
                  border: '2px solid #E8F4D5',
                  backgroundColor: '#46611A!important',
                  color: '#E8F4D5',
                  '&:hover': {
                    backgroundColor: '#82A048!important',
                    border: '2px solid #E8F4D5'
                  }
                }),
                ...(!pathname.includes('shipping-address') &&
                  risk !== 'low' && {
                    opacity: 0.5
                  })
              }}
              onClick={() => (pathname.includes('shipping-address') ? onSetRisk('low') : null)}
            >
              Low
            </Button>

            <Button
              variant="outlined"
              sx={{
                padding: '5px 15px!important',
                backgroundColor: '#FDECD1!important',
                border: '2px solid #FDECD1',
                color: '#845105',
                '&:hover': {
                  backgroundColor: '#FDF2DC!important',
                  border: '2px solid #FDF2DC'
                },
                ...(risk === 'medium' && {
                  border: '2px solid #FDECD1',
                  backgroundColor: '#845105!important',
                  color: '#FDECD1',
                  '&:hover': {
                    backgroundColor: '#B58632!important',
                    border: '2px solid #FDECD1'
                  }
                }),
                ...(!pathname.includes('shipping-address') &&
                  risk !== 'medium' && {
                    opacity: 0.5
                  })
              }}
              onClick={() => (pathname.includes('shipping-address') ? onSetRisk('medium') : null)}
            >
              Medium
            </Button>

            <Button
              variant="outlined"
              sx={{
                padding: '5px 15px!important',
                backgroundColor: '#FFC8CE!important',
                border: '2px solid #FFC8CE',
                color: '#802F00',
                '&:hover': {
                  backgroundColor: '#FFD5D6!important',
                  border: '2px solid #FFD5D6'
                },
                ...(risk === 'high' && {
                  border: '2px solid #FFCDC1',
                  backgroundColor: '#861900!important',
                  color: '#FFCDC1',
                  '&:hover': {
                    backgroundColor: '#B6512D!important',
                    border: '2px solid #FFCDC1'
                  }
                }),
                ...(!pathname.includes('shipping-address') &&
                  risk !== 'high' && {
                    opacity: 0.5
                  })
              }}
              onClick={() => (pathname.includes('shipping-address') ? onSetRisk('high') : null)}
            >
              High
            </Button>
          </Box>

          <Box sx={{ mt: 4, mb: 2, border: 'none', borderTop: '1px dashed #9A9AA2' }} />

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: pathname.includes('shipping-address') ? '1fr 1fr' : '1fr',
              gap: 2
            }}
          >
            <Button
              variant="outlined"
              sx={{
                padding: '12px 16px 12px 24px!important',
                backgroundColor: '#232326!important',
                border: '1px solid #0C0C0D',
                color: '#FFFFFF',
                '&:hover': {
                  backgroundColor: '#75757C!important',
                  border: '1px solid #75757C'
                }
              }}
              onClick={onResetRisk}
            >
              Reset settings
            </Button>

            {pathname.includes('shipping-address') && (
              <Button
                variant="outlined"
                sx={{
                  padding: '12px 16px 12px 24px!important',
                  backgroundColor: '#FF5F00!important',
                  border: '1px solid #FF5F00',
                  color: '#FFFFFF',
                  '&:hover': {
                    backgroundColor: '#FF923F!important',
                    border: '1px solid #FF923F'
                  }
                }}
                onClick={onSaveRisk}
              >
                Save settings
              </Button>
            )}
          </Box>
        </Box>

        <Box sx={{ height: '20px' }} />
      </Popover>
    </Box>
  );
}
