import { format, subWeeks, subYears } from 'date-fns';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import {
  NuDetectState,
  NuDetectSignIn,
  NuDetectSignUp,
  NuDetectApplyCredit,
  NuDetectEditProfile,
  NuDetectPayee,
  NuDetectTransfer,
  NuDetectBillPay,
  NuDetectForgotPassword,
  NuDetectChangePassword
} from '../../@types/NuDetect';
//
import { dispatch } from '../store';
//
import {
  SignInTII as SignInTIITypes,
  CardPaymentTII,
  CouponCodeTII,
  CheckoutTII

} from '../../@types/TII';

// ----------------------------------------------------------------------

const initialState: NuDetectState = {
  isLoading: false,
  user: null,
  isOpenConsole: false,
  webSessionId: '',
  response: null,
  history: [],
  error: null,
  products: [],
  cart: [],
  total : 0,
  discount: 0,
  grandTotal : 0,
  shippingAddress: [],
  billingAddress: [],
  cards : [],
  couponCodeApplied: false,
  giftCardApplied: false,
  environment: [],
  environmentResponse: null,
  couponResponse: null,
  addCardResponse: null,
  checkoutResponse: null,
  checkoutResponseFastPay: null,
  platform_security: '',
  riskLevelTII: '',
  lastPayloadCheckout: {},
  defaultData: {
    shipping: [
      {
        fullName: 'John Smith',
        email: 'johnsmith@mastercard.com',
        dial: '+44',
        phoneNumber: '0272773821',
        streetAddress: '10 Upper Bank St',
        zipCode: 'E14 5NP',
        city: 'London',
        state: 'United Kingdom',
        notes: '',
        addressDetails: '',
        risk: ['unknown', 'low']
      },
      {
        fullName: 'Martha Smith',
        email: 'marthasmith@mastercard.com',
        dial: '+44',
        phoneNumber: '0272773821',
        streetAddress: '10 Upper Bank St',
        zipCode: 'E14 5NP',
        city: 'London',
        state: 'United Kingdom',
        notes: '',
        addressDetails: '',
        risk: ['medium']
      },
      {
        fullName: 'Johan Van Ness',
        email: 'johnsmith@mastercard.com',
        dial: '+44',
        phoneNumber: '0272773821',
        streetAddress: 'Chau. de Tervuren 198',
        zipCode: '1410',
        city: 'Waterloo',
        state: 'Belgium',
        notes: '',
        addressDetails: '',
        risk: ['high']
      }
    ],
    billing: [
      {
        fullName: 'John Smith',
        email: 'johnsmith@mastercard.com',
        dial: '+44',
        phoneNumber: '0272773821',
        streetAddress: '10 Upper Bank St',
        zipCode: 'E14 5NP',
        city: 'London',
        state: 'United Kingdom',
        addressDetails: '',
        risk: ['unknown', 'low']
      },
      {
        fullName: 'John Smith',
        email: 'marthasmith@mastercard.com',
        dial: '+44',
        phoneNumber: '0272773821',
        streetAddress: '10 Upper Bank St',
        zipCode: 'E14 5NP',
        city: 'London',
        state: 'United Kingdom',
        addressDetails: '',
        risk: ['medium']
      },
      {
        fullName: 'John Smith',
        email: 'johnsmith@mastercard.com',
        dial: '+44',
        phoneNumber: '0272773821',
        streetAddress: '10 Upper Bank St',
        zipCode: 'E14 5NP',
        city: 'London',
        state: 'United Kingdom',
        addressDetails: '',
        risk: ['high']
      }
    ],
    payment: [
      {
        cardNumber: '5100754656371348',
        expiryDate: '0130',
        cardName: 'John Smith',
        cvv: '878',
        risk: ['unknown', 'low']
      }, {
        cardNumber: '5100754656371348',
        expiryDate: '0130',
        cardName: 'Martha Smith',
        cvv: '878',
        risk: ['medium']
      }, {
        cardNumber: '5100754656371348',
        expiryDate: '0130',
        cardName: 'Johan Van Ness',
        cvv: '878',
        risk: ['high']
      }
    ],
    response: [
      {
        email: 'johnsmith@mastercard.com',
        emailToNameMatch: 'Match',
        billingAddressFirstSeen: 1034,
        deviceFirstSeen: format(subYears(new Date(), 3), "dd MMM yyyy"), // Current date minus 3 years
        billingAddressToShippingAddress: 'Relationship',
        paymentCardToCardholderNameVelocity: 7,
        phoneLineType: 'Mobile',
        ipDistanceFromShippingAddress: 5,
        billingAddressValidityLevel: 'valid_to_house_number',
        shippingAddressValidityLevel: 'valid_to_house_number',
        risk: ['unknown', 'low']
      },
      {
        email: 'marthasmith@mastercard.com',
        emailToNameMatch: 'Match',
        billingAddressFirstSeen: 210,
        deviceFirstSeen: format(subYears(new Date(), 1), "dd MMM yyyy"), // Current date minus 1 year
        billingAddressToShippingAddress: 'Relationship',
        paymentCardToCardholderNameVelocity: 22,
        phoneLineType: 'Mobile',
        ipDistanceFromShippingAddress: 84,
        billingAddressValidityLevel: 'valid_to_house_number',
        shippingAddressValidityLevel: 'valid_to_house_number',
        risk: ['medium']
      },
      {
        email: 'johnsmith@mastercard.com',
        emailToNameMatch: 'Match',
        billingAddressFirstSeen: 94,
        deviceFirstSeen: format(subWeeks(new Date(), 2), "dd MMM yyyy"), // Current date minus 2 weeks
        billingAddressToShippingAddress: 'No Relationship',
        paymentCardToCardholderNameVelocity: 110,
        phoneLineType: 'VOIP',
        ipDistanceFromShippingAddress: 862,
        billingAddressValidityLevel: 'valid_to_house_number',
        shippingAddressValidityLevel: 'valid_to_house_number',
        risk: ['high']
      }
    ]
  }
};

const slice = createSlice({
  name: 'NuDetect',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.error = null;
      state.response = null;
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      const error = action.payload;

      if (error.response) state.webSessionId = error.response.webSessionId;

      state.response = null;
      state.error = error;
      state.isLoading = false;
    },

    cleanRespose(state) {
      state.response = null;
      state.error = null;
      state.addCardResponse = null;
      state.checkoutResponse = null;
    },

    cleanCheckoutResponse(state){
      state.checkoutResponse = null;
      state.checkoutResponseFastPay = null;
      state.error = null;
    },

    cleanUser(state) {
      state.user = null;
      state.error = null;
    },

    openConsole(state, action) {
      if (state.history.length > 0) state.isOpenConsole = action.payload;
    },

    setHistory(state, action) {
      state.history = [...state.history, action.payload];
    },

    SignInSuccess(state, action) {
      state.error = null;
      state.user = action.payload.user;
      state.webSessionId = action.payload.webSessionId;
      state.response = action.payload;
      state.isLoading = false;
    },

    setRiskLevelTII(state, action){
        state.riskLevelTII = action.payload
    },

    SignUpSuccess(state, action) {
      state.error = null;
      state.user = action.payload.user;
      state.webSessionId = action.payload.webSessionId;
      state.response = action.payload;
      state.isLoading = false;
    },

    ApplyCreditSuccess(state, action) {
      state.error = null;
      state.webSessionId = action.payload.webSessionId;
      state.response = action.payload;
      state.isLoading = false;
    },

    EditProfileSuccess(state, action) {
      state.error = null;
      state.webSessionId = action.payload.webSessionId;
      state.response = action.payload;
      state.isLoading = false;
    },

    PayeeSuccess(state, action) {
      state.error = null;
      state.webSessionId = action.payload.webSessionId;
      state.response = action.payload;
      state.isLoading = false;
    },

    TransferSuccess(state, action) {
      state.error = null;
      state.webSessionId = action.payload.webSessionId;
      state.response = action.payload;
      state.isLoading = false;
    },

    BillPaySuccess(state, action) {
      state.error = null;
      state.webSessionId = action.payload.webSessionId;
      state.response = action.payload;
      state.isLoading = false;
    },

    ForgotPasswordSuccess(state, action) {
      state.error = null;
      state.webSessionId = action.payload.webSessionId;
      state.response = action.payload;
      state.isLoading = false;
    },

    ChangePasswordSuccess(state, action) {
      state.error = null;
      state.webSessionId = action.payload.webSessionId;
      state.response = action.payload;
      state.isLoading = false;
    },
    ShippingAddressSuccess(state, action){
      state.error = null;
      state.webSessionId = action.payload.webSessionId;
      state.response = action.payload;
      state.isLoading = false;
    },
    BillingAddressSuccess(state, action){
      state.error = null;
      state.webSessionId = action.payload.webSessionId;
      state.response = action.payload;
      state.isLoading = false;
    },
    CouponCodeSuccess(state, action){
      state.error = null;
      state.webSessionId = action.payload.webSessionId;
      state.couponResponse = action.payload;
      state.isLoading = false;
    },
    GiftCardSuccess(state, action){
      state.error = null;
      state.webSessionId = action.payload.webSessionId;
      state.response = action.payload;
      state.isLoading = false;
      state.giftCardApplied = true;
    },
    orderSuccess(state,action){
      state.error = null;
      state.webSessionId = action.payload.webSessionId;
      state.checkoutResponse = action.payload;
      state.isLoading = false;
    },
    orderSuccessFastPay(state, action){
      state.error = null;
      state.webSessionId = action.payload.webSessionId;
      state.checkoutResponseFastPay = action.payload;
      state.isLoading = false;
    },
    CardPaymentSuccess(state, action){
      state.error = null;
      state.webSessionId = action.payload.webSessionId;
      state.addCardResponse = action.payload;
      state.isLoading = false;
    },
    setProducts(state, action){
      state.products = action.payload.products
    },
    setProductOnCart(state, action){
      state.cart = [...state.cart, action.payload];
      const quantity = action.payload.quantity;
      const discountedPrice = action.payload.discountedPrice;
      const originalPrice = action.payload.price;

      (state.cart.length >= 0) ? state.discount += (originalPrice - discountedPrice) * quantity
                              :  state.discount = 0;
      state.total += originalPrice * quantity;
      state.grandTotal += discountedPrice * quantity;
    },
    removeProduct(state, action){ 
      const quantity = action.payload.quantity;
      const discountedPrice = action.payload.discountedPrice;
      const originalPrice = action.payload.price;

      state.cart.splice(action.payload.id, 1);
      (state.cart.length > 0) ?  state.total -= + originalPrice * quantity
                              :  state.total = 0;

      state.grandTotal -= discountedPrice * quantity;
      state.discount -= (originalPrice - discountedPrice) * quantity;
    },
    applyCouponCode(state, action){
      const discount = (state.total / 100) * 10;
      state.discount += discount;
      state.grandTotal = state.total - state.discount;
      state.couponCodeApplied = true;
      state.couponResponse = null;
    },
    addProductQuantity(state, action){
      state.cart.map((product: any) =>{
        if(product.id === action.payload.id){
          const discountedPrice = action.payload.discountedPrice;
          const originalPrice = action.payload.price;
          state.total += originalPrice;
          state.grandTotal += discountedPrice;
          (state.cart.length >= 0) ? state.discount += (originalPrice - discountedPrice)
                              :  state.discount = 0;
          product.quantity++;
        }
      })
    },
    removeProductQuantity(state, action){
        state.cart.map((product: any) =>{
          if(product.id === action.payload.id){
            const discountedPrice = action.payload.discountedPrice;
            const originalPrice = action.payload.price;
            state.total -= originalPrice;
            state.grandTotal -= discountedPrice;
            (state.cart.length >= 0) ? state.discount -= (originalPrice - discountedPrice)
                                :  state.discount = 0;
            product.quantity--;
          }
        })
    },
    saveShippingAddress(state, action){
      state.shippingAddress = [...state.shippingAddress, action.payload];
    },
    saveBillingAddress(state, action){
      state.billingAddress = [...state.billingAddress, action.payload];
    },
    saveCard(state, action){
      state.cards = [...state.cards, action.payload];
      state.addCardResponse = null;
    },
    SetDefaultEcommerceEnvironment(state, action){
      let object = localStorage.getItem('environment') || action.payload;
      const platform_security = localStorage.getItem('security')
      state.error = null;
      state.webSessionId = action.payload.webSessionId;
      state.isLoading = false;
      state.environment = (localStorage.getItem('environment')) ? JSON.parse(object) : action.payload;
      state.platform_security = platform_security;
    },
    SetNewEnvironment(state, action){
      let object = localStorage.getItem('environment') || action.payload;
      const platform_security = localStorage.getItem('security')

      state.error = null;
      state.webSessionId = action.payload.webSessionId;
      state.isLoading = false;
      state.environmentResponse = action.payload;
      state.environment = (localStorage.getItem('environment')) ? JSON.parse(object) : action.payload;
      state.platform_security = platform_security;
    },
    cleanOrder(state){
      state.response = null;
      state.cart = [];
      state.total = 0;
      state.grandTotal = 0;
      state.discount = 0;
      state.couponCodeApplied = false;
      state.giftCardApplied = false;
      state.couponResponse = null;
      state.checkoutResponse = null;
    },

    setLastPayloadCheckout(state, action) {
      state.lastPayloadCheckout = action.payload;
    },
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function cleanUser() {
  return async () => dispatch(slice.actions.cleanUser());
}

// ----------------------------------------------------------------------

export function cleanRespose() {
  return async () => dispatch(slice.actions.cleanRespose());
}

// ----------------------------------------------------------------------

export function cleanCheckoutRespose() {
  return async () => dispatch(slice.actions.cleanCheckoutResponse());
}

// ----------------------------------------------------------------------

export function setAllProducts(products: any) {
  return async () =>
    dispatch(
      slice.actions.setProducts({
        ...products
      })
    );
}
export function setProductOnCart(product: any, quantity: number) {
  return async () =>
    dispatch(
      slice.actions.setProductOnCart({
        quantity,
        ...product
      })
    );
}

export function addProductQuantity(product: any, quantity: number){
  return async () =>
  dispatch(
    slice.actions.addProductQuantity({
      quantity,
      ...product
    })
  );
}

export function removeProductQuantity(product: any, quantity: number){
  return async () =>
  dispatch(
    slice.actions.removeProductQuantity({
      quantity,
      ...product
    })
  );
}

export function removeProduct(id: any, price: any, quantity: any, discountedPrice: any){
  return async () =>
  dispatch(
    slice.actions.removeProduct({
        id,
        price,
        quantity,
        discountedPrice
    })
  );
}
export function applyCouponCode(total: any){
  return async () =>
  dispatch(
    slice.actions.applyCouponCode({
        total
    })
  );
}

export function saveShippingAddress(data: any){
  return async () =>
  dispatch(
    slice.actions.saveShippingAddress({
        ...data
    })
  );
}

export function saveBillingAddress(data: any){
  return async () =>
  dispatch(
    slice.actions.saveBillingAddress({
        ...data
    })
  );
}

export function saveNewCard(data: any){
  return async () =>
  dispatch(
    slice.actions.saveCard({
        ...data
    })
  );
}

export function openConsole(open: boolean) {
  return async () => dispatch(slice.actions.openConsole(open));
}

export function setHistory(type: string, endpoint: string, placement: string, data: any) {
  return async () =>
    dispatch(
      slice.actions.setHistory({
        type,
        datetime: format(new Date(), 'eee, dd LLL yyyy HH:mm:ss'),
        endpoint,
        placement,
        ...data
      })
    );
}
// ----------------------------------------------------------------------

export function ShippingAddressRequest(data: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(setHistory('request', '/api/ecommerce/shipping-address', 'ShippingAddress', { request: data }));

      const response = await axios.post('/ecommerce/checkout', data);

      dispatch(setHistory('success', '/ecommerce/shipping-address', 'ShippingAddress', { success: response.data }));

      dispatch(slice.actions.ShippingAddressSuccess(response.data));
    } catch (error) {
      dispatch(setHistory('error', '/ecommerce/shipping-address', 'ShippingAddress', { error }));

      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function BillingAddressRequest(data: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(setHistory('request', '/api/ecommerce/billing-address', 'BillingAddress', { request: data }));

      const response = await axios.post('/ecommerce/checkout', data);

      dispatch(setHistory('success', '/ecommerce/billing-address', 'BillingAddress', { success: response.data }));

      dispatch(slice.actions.BillingAddressSuccess(response.data));
    } catch (error) {
      dispatch(setHistory('error', '/ecommerce/billing-address', 'BillingAddress', { error }));

      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------

export function CouponCodeRequest(data: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(setHistory('request', '/api/ecommerce/coupon-code', 'AddDiscountPurchase', { request: data }));

      const response = await axios.post('/ecommerce/coupon-code', data);

      dispatch(setHistory('success', '/ecommerce/coupon-code', 'AddDiscountPurchase', { success: response.data }));
      dispatch(setHistory('screen', 'Coupon', 'Coupon', {}));

      dispatch(slice.actions.CouponCodeSuccess(response.data));
    } catch (error) {
      dispatch(setHistory('error', '/ecommerce/coupon-code', 'AddDiscountPurchase', { error }));

      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function GiftCardCodeRequest(data: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(setHistory('request', '/api/ecommerce/gift-card', 'AddDiscountPurchase', { request: data }));

      const response = await axios.post('/ecommerce/gift-card', data);

      dispatch(setHistory('success', '/ecommerce/gift-card', 'AddDiscountPurchase', { success: response.data }));

      dispatch(slice.actions.GiftCardSuccess(response.data));
    } catch (error) {
      dispatch(setHistory('error', '/ecommerce/gift-card', 'AddDiscountPurchase', { error }));

      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setOrderRequest(data: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(setHistory('request', '/api/ecommerce/order', 'CheckoutPurchase', { request: data }));

      const response = await axios.post('/ecommerce/checkout', data);

      dispatch(setHistory('success', '/ecommerce/order', 'CheckoutPurchase', { success: response.data }));

      dispatch(slice.actions.orderSuccess(response.data));
    } catch (error) {
      dispatch(setHistory('error', '/ecommerce/order', 'CheckoutPurchase', { error }));

      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setOrderOtherMethodRequest(data: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(setHistory('request', '/api/ecommerce/order', 'CheckoutPurchase', { request: data }));

      const response = await axios.post('/ecommerce/checkout', data);

      dispatch(setHistory('success', '/ecommerce/order', 'CheckoutPurchase', { success: response.data }));

      dispatch(slice.actions.orderSuccessFastPay(response.data));
    } catch (error) {
      dispatch(setHistory('error', '/ecommerce/order', 'CheckoutPurchase', { error }));

      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function saveNewCardRequest(data: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(setHistory('request', '/api/ecommerce/card-payment', 'AddCardPurchase', { request: data }));

      const response = await axios.post('/ecommerce/card-payment', data);

      dispatch(setHistory('success', '/ecommerce/card-payment', 'AddCardPurchase', { success: response.data }));

      dispatch(slice.actions.CardPaymentSuccess(response.data));
    } catch (error) {
      dispatch(setHistory('error', '/ecommerce/card-payment', 'AddCardPurchase', { error }));

      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function SignIn(data: NuDetectSignIn) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(setHistory('request', '/auth/sign-in', 'LoginPurchase', { request: data }));

      const response = await axios.post('/auth/sign-in', data);

      dispatch(setHistory('success', '/auth/sign-in', 'LoginPurchase', { success: response.data }));

      dispatch(slice.actions.SignInSuccess(response.data));
    } catch (error) {
      dispatch(setHistory('error', '/auth/sign-in', 'LoginPurchase', { error }));

      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function SignUp(data: NuDetectSignUp) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(setHistory('request', '/auth/sign-up', 'CreateAccountPurchase', { request: data }));

      const response = await axios.post('/auth/sign-up', data);

      dispatch(setHistory('success', '/auth/sign-up', 'CreateAccountPurchase', { success: response.data }));

      dispatch(slice.actions.SignUpSuccess(response.data));
    } catch (error) {
      dispatch(setHistory('error', '/auth/sign-up', 'CreateAccountPurchase', { error }));

      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function ApplyCredit(data: NuDetectApplyCredit) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(setHistory('request', '/apply-credit', 'ApplyCreditCardWeb', { request: data }));

      const response = await axios.post('/apply-credit', data);

      dispatch(
        setHistory('success', '/apply-credit', 'ApplyCreditCardWeb', { success: response.data })
      );

      dispatch(slice.actions.ApplyCreditSuccess(response.data));
    } catch (error) {
      dispatch(setHistory('error', '/apply-credit', 'ApplyCreditCardWeb', { error }));

      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function EditProfile(data: NuDetectEditProfile) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(setHistory('request', '/user/edit-profile', 'EditProfilePurchase', { request: data }));

      const response = await axios.post('/user/edit-profile', data);

      dispatch(
        setHistory('success', '/user/edit-profile', 'EditProfilePurchase', { success: response.data })
      );

      dispatch(slice.actions.EditProfileSuccess(response.data));
    } catch (error) {
      dispatch(setHistory('error', '/user/edit-profile', 'EditProfilePurchase', { error }));

      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function Payee(data: NuDetectPayee) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(setHistory('request', '/payee', 'AddNewPayeeWeb', { request: data }));

      const response = await axios.post('/payee', data);

      dispatch(setHistory('success', '/payee', 'AddNewPayeeWeb', { success: response.data }));

      dispatch(slice.actions.PayeeSuccess(response.data));
    } catch (error) {
      dispatch(setHistory('error', '/payee', 'AddNewPayeeWeb', { error }));

      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function Transfer(data: NuDetectTransfer) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(setHistory('request', '/transfer', 'SendTransferWeb', { request: data }));

      const response = await axios.post('/transfer', data);

      dispatch(setHistory('success', '/transfer', 'SendTransferWeb', { success: response.data }));

      dispatch(slice.actions.TransferSuccess(response.data));
    } catch (error) {
      dispatch(setHistory('error', '/transfer', 'SendTransferWeb', { error }));

      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function BillPay(data: NuDetectBillPay) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(setHistory('request', '/bill-pay', 'PayBillWeb', { request: data }));

      const response = await axios.post('/bill-pay', data);

      dispatch(setHistory('success', '/bill-pay', 'PayBillWeb', { success: response.data }));

      dispatch(slice.actions.BillPaySuccess(response.data));
    } catch (error) {
      dispatch(setHistory('error', '/bill-pay', 'PayBillWeb', { error }));

      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function ForgotPassword(data: NuDetectForgotPassword) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(
        setHistory('request', '/auth/forgot-password', 'UpdatePasswordPurchase', { request: data })
      );

      const response = await axios.post('/auth/forgot-password', data);

      dispatch(
        setHistory('success', '/auth/forgot-password', 'UpdatePasswordPurchase', {
          success: response.data
        })
      );

      dispatch(slice.actions.ForgotPasswordSuccess(response.data));
    } catch (error) {
      dispatch(setHistory('error', '/auth/forgot-password', 'UpdatePasswordPurchase', { error }));

      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function ChangePassword(data: NuDetectChangePassword) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(
        setHistory('request', '/auth/forgot-password', 'PasswordResetWeb', { request: data })
      );

      const response = await axios.post('/user/password-reset', data);

      dispatch(
        setHistory('success', '/auth/forgot-password', 'PasswordResetWeb', {
          success: response.data
        })
      );

      dispatch(slice.actions.ChangePasswordSuccess(response.data));
    } catch (error) {
      dispatch(setHistory('error', '/auth/forgot-password', 'PasswordResetWeb', { error }));

      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getDefaultEcommerceEnvironment() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      
      const response = await axios.get('/panel/environment/default/e-commerce-generic-demo-environment');
      localStorage.setItem('security',response.data.security);
      
      dispatch(slice.actions.SetDefaultEcommerceEnvironment(response.data));
    } catch (error) {
      dispatch(setHistory('error', '/panel/environment/default/e-commerce-generic-demo-environment', 'EcommerceGenericDemoEnvironment', { error }));

      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function localStorageEnvironment() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      
      const response = localStorage.getItem('environment')
      
      dispatch(slice.actions.SetDefaultEcommerceEnvironment(response));
    } catch (error) {
      dispatch(setHistory('error', '/panel/environment/default/fi-generic-demo-environment', 'FIGenericDemoEnvironment', { error }));

      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function ActivateEnvironment(environmentID: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      
      const response = await axios.get(`/panel/environment/code/${environmentID}`);

      const data_string = JSON.stringify(response.data)
     
      localStorage.setItem('environment',data_string);
      localStorage.setItem('security',response.data.security);
      
      dispatch(slice.actions.SetNewEnvironment(response.data));
    } catch (error) {
      dispatch(setHistory('error', '/panel/environment/code/', 'EcommerceGenericDemoEnvironment', { error }));

      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function CleanOrder() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      
      dispatch(slice.actions.cleanOrder());
    } catch (error) {
      
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
// --------------TII FUNCTIONS-------------------------------------------

export function SignInTII(data: SignInTIITypes) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(setHistory('request', '/transaction-identity-insights/sign-in', 'Login TransactionIdentityInsights', { request: data }));

      const response = await axios.post('/transaction-identity-insights/sign-in', data);

      dispatch(setHistory('success', '/transaction-identity-insights/sign-in', 'Login TransactionIdentityInsights', { success: response.data }));
      
      dispatch(slice.actions.SignInSuccess(response.data));
      dispatch(slice.actions.setRiskLevelTII(data.riskLevel));
    } catch (error) {
      dispatch(setHistory('error', '/transaction-identity-insights/sign-in', 'Login TransactionIdentityInsights', { error }));

      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setRiskLevelTII(riskLevel: string){
  return async () => {
    dispatch(slice.actions.setRiskLevelTII(riskLevel));
  }
}

// ----------------------------------------------------------------------

export function saveNewCardRequestTII(data: CardPaymentTII) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(setHistory('request', '/transaction-identity-insights/ecommerce/card-payment', 'AddCardPurchase TransactionIdentityInsights', { request: data }));

      const response = await axios.post('/transaction-identity-insights/ecommerce/card-payment', data);

      dispatch(setHistory('success', '/transaction-identity-insights/ecommerce/card-payment', 'AddCardPurchase TransactionIdentityInsights', { success: response.data }));
      
      dispatch(slice.actions.CardPaymentSuccess(response.data));
    } catch (error) {
      dispatch(setHistory('error', '/transaction-identity-insights/ecommerce/card-payment', 'AddCardPurchase TransactionIdentityInsights', { error }));

      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function CouponCodeRequestTII(data: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(setHistory('request', '/transaction-identity-insights/ecommerce/coupon-code', 'AddDiscountPurchase TransactionIdentityInsights', { request: data }));

      const response = await axios.post('/transaction-identity-insights/ecommerce/coupon-code', data);

      dispatch(setHistory('success', '/transaction-identity-insights/ecommerce/coupon-code', 'AddDiscountPurchase TransactionIdentityInsights', { success: response.data }));
      dispatch(setHistory('screen', 'Coupon', 'Coupon', {}));

      dispatch(slice.actions.CouponCodeSuccess(response.data));
    } catch (error) {
      dispatch(setHistory('error', '/transaction-identity-insights/ecommerce/coupon-code', 'AddDiscountPurchase TransactionIdentityInsights', { error }));

      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function ShippingAddressRequestTII(data: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(setHistory('request', '/transaction-identity-insights/ecommerce/shipping-address', 'ShippingAddress', { request: data }));

      const response = await axios.post('/transaction-identity-insights/ecommerce/checkout', data);

      dispatch(setHistory('success', '/transaction-identity-insights/ecommerce/checkout', 'ShippingAddress', { success: response.data }));

      dispatch(slice.actions.ShippingAddressSuccess(response.data));
    } catch (error) {
      dispatch(setHistory('error', '/transaction-identity-insights/ecommerce/checkout', 'ShippingAddress', { error }));

      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function BillingAddressRequestTII(data: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(setHistory('request', '/transaction-identity-insights/ecommerce/billing-address', 'BillingAddress', { request: data }));

      const response = await axios.post('/transaction-identity-insights/ecommerce/checkout', data);

      dispatch(setHistory('success', '/transaction-identity-insights/ecommerce/checkout', 'BillingAddress', { success: response.data }));

      dispatch(slice.actions.BillingAddressSuccess(response.data));
    } catch (error) {
      dispatch(setHistory('error', '/transaction-identity-insights/ecommerce/checkout', 'BillingAddress', { error }));

      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setOrderRequestTII(data: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(setHistory('request', '/transaction-identity-insights/ecommerce/checkout', 'CheckoutPurchase', { request: data }));

      const response = await axios.post('/transaction-identity-insights/ecommerce/checkout', data);

      dispatch(setHistory('success', '/transaction-identity-insights/ecommerce/checkout', 'CheckoutPurchase', { success: response.data }));

      dispatch(slice.actions.orderSuccess(response.data));
    } catch (error) {
      dispatch(setHistory('error', '/transaction-identity-insights/ecommerce/checkout', 'CheckoutPurchase', { error }));

      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setOrderOtherMethodRequestTII(data: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(setHistory('request', '/transaction-identity-insights/ecommerce/checkout', 'CheckoutPurchase', { request: data }));

      const response = await axios.post('/transaction-identity-insights/ecommerce/checkout', data);

      dispatch(setHistory('success', '/transaction-identity-insights/ecommerce/checkout', 'CheckoutPurchase', { success: response.data }));

      dispatch(slice.actions.orderSuccessFastPay(response.data));
    } catch (error) {
      dispatch(setHistory('error', '/transaction-identity-insights/ecommerce/checkout', 'CheckoutPurchase', { error }));

      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setLastPayloadCheckout(data: any) {
  return async () => {
    dispatch(slice.actions.setLastPayloadCheckout(data));
  };
}


