import { v4 } from 'uuid';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
// hook
// import useResponsive from './hooks/useResponsive';
// theme
import ThemeProvider from './theme';
// routes
import Router from './routes';
//
import Console from './sections/Console';
import FloatingButtonConsole from './components/FloatingButtonConsole';
import DialogIdentityInsightsTransactions from './components/DialogIdentityInsightsTransactions';
import DemoSettings from './components/DialogIdentityInsightsTransactions/DemoSettings';
import DialogTransactionInfo from './components/DialogIdentityInsightsTransactions/TransactionInfo';

export default function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    // The NDS library requires an Unique ID for the session
    const session = v4();
    sessionStorage.setItem('session', session);

    // navigator.geolocation.getCurrentPosition(
    //   (position) => {
    //     const location = JSON.stringify(position);

    //     console.log(location);
    //   },
    //   (error) => console.log(error),
    //   { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
    // );
  }, []);

  return (
    <ThemeProvider>
      <Stack direction="row" display={{ xs: 'inherit', md: 'flex' }}>
        <Box flex={{ md: 1 }} height={{ md: '100vh' }} sx={{ overflowY: { md: 'scroll' } }}>
          <Router />
        </Box>

        <Console />
      </Stack>

      {/* This tag is necessary by NDS library, the NDS library insert a input (name="nds-pmd") with a payload */}
      <form />

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns:
            pathname.includes('shipping-address') ||
            pathname.includes('billing-address') ||
            pathname.includes('payment-method') ||
            pathname.includes('order-placed')
              ? 'auto auto auto'
              : 'auto auto',
          gap: 2,
          position: 'fixed',
          bottom: 20,
          right: 30
        }}
      >
        {pathname.includes('order-placed') && <DialogTransactionInfo />}

        {(pathname.includes('shipping-address') ||
          pathname.includes('billing-address') ||
          pathname.includes('payment-method')) && <DemoSettings />}

        <FloatingButtonConsole />

        <DialogIdentityInsightsTransactions />
      </Box>
    </ThemeProvider>
  );
}
