import { useState } from 'react';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
//
import { useSelector } from '../../redux/store';
// @mui
import { Fab, Button, Box, Dialog, DialogContent, Popover, Divider } from '@mui/material';
import Iconify from '../Iconify';
//
import logo from '../../assets/logo.png';

// ----------------------------------------------------------------------

export default function DialogTransactionInfo() {
  const navigate = useNavigate();

  const {
    isOpenConsole,
    platform_security,
    environment,
    riskLevelTII,
    lastPayloadCheckout,
    grandTotal,
    defaultData: { response }
  } = useSelector((state) => state.nuDetectState);
  const [open, setOpen] = useState(false);
  const [info, seInfo] = useState<string | null>(null);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>, _info: string) => {
    seInfo(_info);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    seInfo(null);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getInfo = () => {
    const data: any = {
      title: '',
      description: '',
      byRisk: false,
      bullets: false
    };

    switch (info) {
      case 'ip-risk':
        data.title = 'About IP Risk';
        data.description = {
          unknown: [
            'IP has been historically associated with these identity elements and is a short distance from the billing and shipping address provided.'
          ],
          low: [
            'IP has been historically associated with these identity elements and is a short distance from the billing and shipping address provided.'
          ],
          medium: [
            'IP has been historically associated with these identity elements and is a short distance from the billing and shipping address provided.'
          ],
          high: [
            "This IP address has been associated with risky behavior such as proxy's, public WiFi's, and submitting several transactions in a short period of time."
          ]
        };
        data.byRisk = true;
        data.bullets = true;
        break;
      case 'identity-risk':
        data.title = 'About identity Risk';
        data.description = [
          "Email, phone, and addresses are either not found or couldn't be validated.",
          'IP geolocation does not match physical address.',
          'Primary address used in 28 digital interactions in 1 month.',
          'Primary address used in 28 digital interactions in 1 month.'
        ];
        data.description = {
          unknown: [
            'Email, Phone, and Address are valid and match to the name provided.',
            'Email and address were first seen together over 2 years ago.',
            'Primary address and email were used together in only 3 transactions over the past 3 months.'
          ],
          low: [
            'Email, Phone, and Address are valid and match to the name provided.',
            'Email and address were first seen together over 2 years ago.',
            'Primary address and email were used together in only 3 transactions over the past 3 months.'
          ],
          medium: [
            'Email, Phone, and Address are valid and match to the name provided.',
            'Email and address were first seen together over 2 years ago.',
            'Primary address and email were used together in only 3 transactions over the past 3 months.'
          ],
          high: [
            "Email, phone, and addresses are either not found or couldn't be validated.",
            'IP geolocation does not match physical address.',
            'Primary address used in 28 digital interactions in 1 month.',
            'Primary address used in 28 digital interactions in 1 month.'
          ]
        };
        data.byRisk = true;
        data.bullets = true;
        break;
      case 'reason-code':
        data.title = 'About Reason Code';
        data.description =
          'IP rsk over0,5 and this IP has seen with fraudulent activity in the past';
        break;
      case 'device-risk':
        data.title = 'About Device Risk Factor';
        data.description = {
          unknown: [
            'No history of fraud in network The IP network and device have not been linked to previous fraud across our networks.',
            'Genuine device attributes The device type, browser, and operating system are genuine and are commonly used in the use case.'
          ],
          low: [
            'No history of fraud in network The IP network and device have not been linked to previous fraud across our networks.',
            'Genuine device attributes The device type, browser, and operating system are genuine and are commonly used in the use case.'
          ],
          medium: [
            'No history of fraud in network The IP network and device have not been linked to previous fraud across our networks.',
            'Genuine device attributes The device type, browser, and operating system are genuine and are commonly used in the use case.'
          ],
          high: [
            'High traffic volume from device The device has been seen an unusual amount of times across the network within a short period of time.',
            'Impossible device attributes The device shows a type of operating system or browser version that doesn’t exist, indicating a spoofed device.'
          ]
        };
        data.byRisk = true;
        data.bullets = true;
        break;
      case 'payment-signals':
        data.title = 'About Payment Signals';
        data.description =
          'IP rsk over0,5 and this IP has seen with fraudulent activity in the past';
        break;
      case 'email-risk':
        data.title = 'About Email Risks Score';
        data.description = {
          unknown: [
            'Email has been first seen over 2 years ago',
            'Email has been associated with only 2 transactions in the previous month'
          ],
          low: [
            'Email has been first seen over 2 years ago',
            'Email has been associated with only 2 transactions in the previous month'
          ],
          medium: [
            'Email is less than 1 week old',
            'Email has been seen in 15 or more transactions in the past 24 hours'
          ],
          high: [
            'Email is less than 1 week old',
            'Email has been seen in 15 or more transactions in the past 24 hours'
          ]
        };
        data.byRisk = true;
        data.bullets = true;
        break;

      default:
        break;
    }

    return data;
  };

  const onRefreshData = () => {
    navigate('/shipping-address');
    handleClose();
  };

  if (isOpenConsole || platform_security !== 'TransactionIdentityInsights') return null;

  return (
    <Box>
      <Fab
        color="default"
        onClick={handleOpen}
        sx={{
          backgroundColor: '#0C0C0D',
          '&:hover': {
            backgroundColor: '#67676D'
          }
        }}
      >
        <LogoChart />
      </Fab>

      <Dialog
        onClose={handleClose}
        open={open}
        scroll="body"
        sx={{ '& .MuiDialog-paper': { minWidth: '1350px' } }}
      >
        <DialogContent>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '104px 138px',
              justifyContent: 'space-between'
            }}
          >
            <Box>
              <img src={logo} alt="overlap" width="104px" />
              <Divider
                sx={{ margin: 0, height: 5, backgroundColor: environment.color, border: 'none' }}
              ></Divider>
            </Box>

            <Button
              variant="outlined"
              sx={{
                padding: '12px 16px 12px 24px!important',
                backgroundColor: '#DD8608!important',
                border: '1px solid #DD8608',
                color: '#FFFFFF',
                '&:hover': {
                  backgroundColor: '#EAAC41!important',
                  border: '1px solid #EAAC41'
                }
              }}
              onClick={onRefreshData}
            >
              Restart
            </Button>
          </Box>

          <Box
            component="p"
            sx={{
              fontSize: '32px',
              fontWeight: 400,
              lineHeight: '48px',
              letterSpacing: '0.016em',
              color: '#3B3B3F',
              my: 2
            }}
          >
            Transaction info
          </Box>

          <Box>
            <Box
              component="p"
              sx={{
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '24px',
                letterSpacing: '0.016em',
                color: '#3B3B3F',
                m: 0
              }}
            >
              Risk decisioning
            </Box>

            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'max-content max-content max-content max-content',
                gap: 2
              }}
            >
              <Box
                component="p"
                sx={{
                  fontSize: '20px',
                  fontWeight: 700,
                  lineHeight: '32px',
                  letterSpacing: '0.016em',
                  color: '#696972',
                  m: 0
                }}
              >
                {
                  {
                    unknown: 'Unknown risk',
                    low: 'Low risk',
                    medium: 'Elevated risk',
                    high: 'Substantial risk'
                  }[riskLevelTII || 'unknown']
                }
                {' -'}
              </Box>

              <Box
                component="p"
                sx={{
                  fontSize: '20px',
                  fontWeight: 700,
                  lineHeight: '32px',
                  letterSpacing: '0.016em',
                  color: {
                    unknown: '#76A22B',
                    low: '#76A22B',
                    medium: '#DD8608',
                    high: '#C92500'
                  }[riskLevelTII || 'unknown'],
                  m: 0
                }}
              >
                {
                  {
                    unknown: 'Checkout approved',
                    low: 'Checkout approved',
                    medium: 'ID required',
                    high: 'Refuse checkout'
                  }[riskLevelTII || 'unknown']
                }
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  width: '32px',
                  height: '32px',
                  borderRadius: '4px',
                  bgcolor: '#E6E6E8',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Box
                  component="p"
                  sx={{
                    fontSize: '14px',
                    fontWeight: 700,
                    lineHeight: '16px',
                    letterSpacing: '0.016em',
                    color: '#696972',
                    m: 0
                  }}
                >
                  {
                    {
                      unknown: '0',
                      low: '0',
                      medium: '4',
                      high: '17'
                    }[riskLevelTII || 'unknown']
                  }
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  width: '32px',
                  height: '32px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#DD8608'
                }}
              >
                <Iconify icon="flowbite:caret-down-solid" />
              </Box>
            </Box>
          </Box>

          <Box
            component="p"
            sx={{
              fontSize: '24px',
              fontWeight: 400,
              lineHeight: '32px',
              letterSpacing: '0.016em',
              color: '#3B3B3F',
              mx: 0,
              mt: 2,
              mb: 1
            }}
          >
            Scores
          </Box>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(6, max-content)',
              gap: 4,
              border: '1px dashed #B3B3B9',
              padding: '8px 24px',
              borderRadius: '4px',
              maxWidth: 'fit-content'
            }}
          >
            <Box sx={{ maxWidth: 'fit-content' }}>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 24px',
                  maxWidth: 'fit-content',
                  gap: 1
                }}
              >
                <Box
                  component="p"
                  sx={{
                    fontSize: '13px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    letterSpacing: '0.016em',
                    color: '#81818B',
                    textTransform: 'uppercase',
                    m: 0
                  }}
                >
                  IP Risk
                </Box>

                <Iconify
                  icon="material-symbols:info-outline"
                  sx={{ color: '#FF233C', cursor: 'pointer' }}
                  onMouseEnter={(event) => handlePopoverOpen(event, 'ip-risk')}
                  onMouseLeave={handlePopoverClose}
                />
              </Box>

              <Box
                component="p"
                sx={{
                  fontSize: '32px',
                  fontWeight: 400,
                  lineHeight: '48px',
                  letterSpacing: '0.016em',
                  color: {
                    unknown: '#76A22B',
                    low: '#76A22B',
                    medium: '#DD8608',
                    high: '#C92500'
                  }[riskLevelTII || 'unknown'],
                  textAlign: 'center',
                  m: 0
                }}
              >
                {
                  {
                    unknown: '0.205',
                    low: '0.205',
                    medium: '0.342',
                    high: '0.989'
                  }[riskLevelTII || 'unknown']
                }
              </Box>
            </Box>

            <Box sx={{ maxWidth: 'fit-content' }}>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 24px',
                  maxWidth: 'fit-content',
                  gap: 1
                }}
              >
                <Box
                  component="p"
                  sx={{
                    fontSize: '13px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    letterSpacing: '0.016em',
                    color: '#81818B',
                    textTransform: 'uppercase',
                    m: 0
                  }}
                >
                  identity Risk
                </Box>

                <Iconify
                  icon="material-symbols:info-outline"
                  sx={{ color: '#FF233C', cursor: 'pointer' }}
                  onMouseEnter={(event) => handlePopoverOpen(event, 'identity-risk')}
                  onMouseLeave={handlePopoverClose}
                />
              </Box>

              <Box
                component="p"
                sx={{
                  fontSize: '32px',
                  fontWeight: 400,
                  lineHeight: '48px',
                  letterSpacing: '0.016em',
                  color: {
                    unknown: '#76A22B',
                    low: '#76A22B',
                    medium: '#DD8608',
                    high: '#C92500'
                  }[riskLevelTII || 'unknown'],
                  textAlign: 'center',
                  m: 0
                }}
              >
                {
                  {
                    unknown: '168',
                    low: '168',
                    medium: '375',
                    high: '480'
                  }[riskLevelTII || 'unknown']
                }
              </Box>
            </Box>

            {/* <Box sx={{ maxWidth: 'fit-content' }}>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 24px',
                  maxWidth: 'fit-content',
                  gap: 1
                }}
              >
                <Box
                  component="p"
                  sx={{
                    fontSize: '13px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    letterSpacing: '0.016em',
                    color: '#81818B',
                    textTransform: 'uppercase',
                    m: 0
                  }}
                >
                  Reason code
                </Box>

                <Iconify
                  icon="material-symbols:info-outline"
                  sx={{ color: '#FF233C', cursor: 'pointer' }}
                  onMouseEnter={(event) => handlePopoverOpen(event, 'reason-code')}
                  onMouseLeave={handlePopoverClose}
                />
              </Box>

              <Box
                component="p"
                sx={{
                  fontSize: '32px',
                  fontWeight: 400,
                  lineHeight: '48px',
                  letterSpacing: '0.016em',
                  color: {
                    unknown: '#76A22B',
                    low: '#76A22B',
                    medium: '#DD8608',
                    high: '#C92500'
                  }[riskLevelTII || 'unknown'],
                  textAlign: 'center',
                  m: 0
                }}
              >
                JA
              </Box>
            </Box> */}

            <Box sx={{ maxWidth: 'fit-content' }}>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 24px',
                  maxWidth: 'fit-content',
                  gap: 1
                }}
              >
                <Box
                  component="p"
                  sx={{
                    fontSize: '13px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    letterSpacing: '0.016em',
                    color: '#81818B',
                    textTransform: 'uppercase',
                    m: 0
                  }}
                >
                  Device Risk Factor
                </Box>

                <Iconify
                  icon="material-symbols:info-outline"
                  sx={{ color: '#FF233C', cursor: 'pointer' }}
                  onMouseEnter={(event) => handlePopoverOpen(event, 'device-risk')}
                  onMouseLeave={handlePopoverClose}
                />
              </Box>

              <Box
                component="p"
                sx={{
                  fontSize: '32px',
                  fontWeight: 400,
                  lineHeight: '48px',
                  letterSpacing: '0.016em',
                  color: {
                    unknown: '#76A22B',
                    low: '#76A22B',
                    medium: '#DD8608',
                    high: '#C92500'
                  }[riskLevelTII || 'unknown'],
                  textAlign: 'center',
                  m: 0
                }}
              >
                {
                  {
                    unknown: '1',
                    low: '1',
                    medium: '3',
                    high: '5'
                  }[riskLevelTII || 'unknown']
                }
              </Box>
            </Box>

            {/* <Box sx={{ maxWidth: 'fit-content' }}>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 24px',
                  maxWidth: 'fit-content',
                  gap: 1
                }}
              >
                <Box
                  component="p"
                  sx={{
                    fontSize: '13px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    letterSpacing: '0.016em',
                    color: '#81818B',
                    textTransform: 'uppercase',
                    m: 0
                  }}
                >
                  Payment Signals
                </Box>

                <Iconify
                  icon="material-symbols:info-outline"
                  sx={{ color: '#FF233C', cursor: 'pointer' }}
                  onMouseEnter={(event) => handlePopoverOpen(event, 'payment-signals')}
                  onMouseLeave={handlePopoverClose}
                />
              </Box>

              <Box
                component="p"
                sx={{
                  fontSize: '32px',
                  fontWeight: 400,
                  lineHeight: '48px',
                  letterSpacing: '0.016em',
                  color: {
                    unknown: '#76A22B',
                    low: '#76A22B',
                    medium: '#DD8608',
                    high: '#C92500'
                  }[riskLevelTII || 'unknown'],
                  textAlign: 'center',
                  m: 0
                }}
              >
                AA
              </Box>
            </Box> */}

            <Box sx={{ maxWidth: 'fit-content' }}>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 24px',
                  maxWidth: 'fit-content',
                  gap: 1
                }}
              >
                <Box
                  component="p"
                  sx={{
                    fontSize: '13px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    letterSpacing: '0.016em',
                    color: '#81818B',
                    textTransform: 'uppercase',
                    m: 0
                  }}
                >
                  Email Risk Score
                </Box>

                <Iconify
                  icon="material-symbols:info-outline"
                  sx={{ color: '#FF233C', cursor: 'pointer' }}
                  onMouseEnter={(event) => handlePopoverOpen(event, 'email-risk')}
                  onMouseLeave={handlePopoverClose}
                />
              </Box>

              <Box
                component="p"
                sx={{
                  fontSize: '32px',
                  fontWeight: 400,
                  lineHeight: '48px',
                  letterSpacing: '0.016em',
                  color: {
                    unknown: '#76A22B',
                    low: '#76A22B',
                    medium: '#DD8608',
                    high: '#C92500'
                  }[riskLevelTII || 'unknown'],
                  textAlign: 'center',
                  m: 0
                }}
              >
                {
                  {
                    unknown: '0.211',
                    low: '0.211',
                    medium: '0.359',
                    high: '0.979'
                  }[riskLevelTII || 'unknown']
                }
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'auto 1fr',
              alignItems: 'center',
              gap: 2,
              mx: 0,
              my: 3
            }}
          >
            <Box
              component="p"
              sx={{
                fontSize: '24px',
                fontWeight: 400,
                lineHeight: '32px',
                letterSpacing: '0.016em',
                color: '#3B3B3F',
                m: 0
              }}
            >
              Risk Insights
            </Box>

            <Box
              sx={{
                borderTop: '1px dashed #B3B3B9',
                height: '1px'
              }}
            />
          </Box>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr 1fr',
              gap: 3,
              my: 6
            }}
          >
            {[
              {
                key: 'email_name_match',
                label: 'Email to name match',
                value:
                  platform_security === 'TransactionIdentityInsights'
                    ? response.find((s) => s.risk.includes(riskLevelTII || 'unknown')).email
                    : 'johnsmith@mastercard.com'
              },
              {
                key: 'billing_address_first_seen',
                label: 'Billing address first seen',
                value:
                  platform_security === 'TransactionIdentityInsights'
                    ? response.find((s) => s.risk.includes(riskLevelTII || 'unknown'))
                        .billingAddressFirstSeen
                    : 1034
              },
              {
                key: 'device_first_seen',
                label: 'Device first seen',
                value:
                  platform_security === 'TransactionIdentityInsights'
                    ? response.find((s) => s.risk.includes(riskLevelTII || 'unknown'))
                        .deviceFirstSeen
                    : '19 Nov 2023'
              },
              {
                key: 'billing_address_shipping_address',
                label: 'Billing address to shipping address',
                value:
                  platform_security === 'TransactionIdentityInsights'
                    ? response.find((s) => s.risk.includes(riskLevelTII || 'unknown'))
                        .billingAddressToShippingAddress
                    : 'Relationship'
              },
              {
                key: 'payment_card_to_card_holder_name_velocity',
                label: 'Payment card to card holder name velocity',
                value:
                  platform_security === 'TransactionIdentityInsights'
                    ? response.find((s) => s.risk.includes(riskLevelTII || 'unknown'))
                        .paymentCardToCardholderNameVelocity
                    : 7
              },
              {
                key: 'phone_line_type',
                label: 'Phone line type',
                value:
                  platform_security === 'TransactionIdentityInsights'
                    ? response.find((s) => s.risk.includes(riskLevelTII || 'unknown')).phoneLineType
                    : 'VOIP'
              },
              {
                key: 'ip_distance_from_shipping_address',
                label: 'IP distance from shipping address',
                value:
                  platform_security === 'TransactionIdentityInsights'
                    ? response.find((s) => s.risk.includes(riskLevelTII || 'unknown'))
                        .ipDistanceFromShippingAddress
                    : 5
              },
              {
                key: 'billing_address_validity_level',
                label: 'Billing address validity level',
                value:
                  platform_security === 'TransactionIdentityInsights'
                    ? response.find((s) => s.risk.includes(riskLevelTII || 'unknown'))
                        .billingAddressValidityLevel
                    : 'valid_to_house_number'
              },
              {
                key: 'shipping_address_validity_level',
                label: 'Shipping address validity level',
                value:
                  platform_security === 'TransactionIdentityInsights'
                    ? response.find((s) => s.risk.includes(riskLevelTII || 'unknown'))
                        .shippingAddressValidityLevel
                    : 'valid_to_house_number'
              }
            ].map(({ key, label, value }, i) => (
              <Box key={i}>
                <Box
                  component="p"
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    letterSpacing: '0.016em',
                    color: '#81818B',
                    m: 0
                  }}
                >
                  {label}
                </Box>

                <Box
                  component="p"
                  sx={{
                    fontSize: '18px',
                    fontWeight: 700,
                    lineHeight: '24px',
                    letterSpacing: '0.016em',
                    color: '#3B3B3F',
                    wordBreak: 'break-word',
                    ...([''].includes(key) &&
                      ['high'].includes(riskLevelTII || 'unknown') && {
                        color: '#C92500'
                      }),
                    m: 0
                  }}
                >
                  {value}
                </Box>
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'auto 1fr',
              alignItems: 'center',
              gap: 2,
              mx: 0,
              my: 3
            }}
          >
            <Box
              component="p"
              sx={{
                fontSize: '24px',
                fontWeight: 400,
                lineHeight: '32px',
                letterSpacing: '0.016em',
                color: '#3B3B3F',
                m: 0
              }}
            >
              Transaction info
            </Box>

            <Box
              sx={{
                borderTop: '1px dashed #B3B3B9',
                height: '1px'
              }}
            />
          </Box>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr 1fr',
              gap: 3,
              my: 6
            }}
          >
            {[
              {
                key: 'purchase_date',
                label: 'Purchase date',
                value: format(
                  new Date(lastPayloadCheckout?.order?.date || '2023-09-22'),
                  'yyyy / MM / dd'
                )
              },
              {
                key: 'purchase_amount',
                label: 'Purchase amount',
                value: `$${grandTotal.toFixed(2)} USD`
              },
              // {
              //   key: 'purchase_currency_exponent',
              //   label: 'Purchase currency exponent',
              //   value: '2'
              // },
              {
                key: 'ip_address',
                label: 'IP Address',
                value: lastPayloadCheckout?.ipAddress || '198.51.100.42'
              },
              {
                key: 'purchase_currency',
                label: 'Purchase currency',
                value: 840
              },
              // ...(['high'].includes(riskLevelTII || 'unknown')
              //   ? [
              //       {
              //         key: 'hashed_account_id',
              //         label: 'Hashed Account ID',
              //         value: 'd1e8a70b5ccab1dc2f56bbf7e99f064a660c08e361a3'
              //       }
              //     ]
              //   : []),
              // { key: 'country_code', label: 'Country code', value: 'Russia' },
              // {
              //   key: 'local_storage',
              //   label: 'localStorage',
              //   value: lastPayloadCheckout?.localStorage || 'True'
              // },
              // {
              //   key: 'language',
              //   label: 'Language',
              //   value: lastPayloadCheckout?.language || 'Zh-TW'
              // },
              // {
              //   key: 'merchant_name',
              //   label: 'Merchant name',
              //   value: lastPayloadCheckout?.merchantName || 'Fraud Inc'
              // },
              // {
              //   key: 'cookies_enabled',
              //   label: 'Cookies enabled',
              //   value: lastPayloadCheckout?.cookiesEnabled || true ? 'True' : 'False'
              // },
              // {
              //   key: 'acquirer_merchant_id',
              //   label: 'Acquirer merchant ID',
              //   value: 'acquirerMerchantId'
              // },
              // ...(['high'].includes(riskLevelTII || 'unknown')
              //   ? [{ key: 'acquirer_bin', label: 'Acquirer Bin', value: '66666' }]
              //   : []),
              {
                key: 'card_older_name',
                label: 'Card holder name',
                value: lastPayloadCheckout?.cardCheckout?.cardName || 'Waidong L Syrws'
              },
              // {
              //   key: 'secondary_card_older_name',
              //   label: 'Secondary Card holder name',
              //   value: 'Waidong L Syrws'
              // },
              {
                key: 'phone_number',
                label: 'Phone Number',
                value: [
                  lastPayloadCheckout?.billingAddressCheckout?.dial || '+44',
                  lastPayloadCheckout?.billingAddressCheckout?.phoneNumber || '0272773821'
                ].join(' ')
              },
              {
                key: 'primary_email',
                label: 'Primary Email',
                value:
                  lastPayloadCheckout?.billingAddressCheckout?.email || 'jansmit@mastercard.com'
              }
              // ...(['high'].includes(riskLevelTII || 'unknown')
              //   ? [
              //       {
              //         key: 'secondary_email',
              //         label: 'Secondary Email',
              //         value: 'missfraud@fraud.com'
              //       },
              //       { key: 'subscriber', label: 'Subscriber', value: '99999' }
              //     ]
              //   : [])
            ].map(({ key, label, value }, i) => (
              <Box key={i}>
                <Box
                  component="p"
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    letterSpacing: '0.016em',
                    color: '#81818B',
                    m: 0
                  }}
                >
                  {label}
                </Box>

                <Box
                  component="p"
                  sx={{
                    fontSize: '18px',
                    fontWeight: 700,
                    lineHeight: '24px',
                    letterSpacing: '0.016em',
                    color: '#3B3B3F',
                    wordBreak: 'break-word',
                    ...([
                      'purchase_amount',
                      'purchase_currency_exponent',
                      'purchase_currency',
                      'hashed_account_id',
                      'acquirer_bin',
                      'primary_email',
                      'secondary_email',
                      'subscriber'
                    ].includes(key) &&
                      ['high'].includes(riskLevelTII || 'unknown') && {
                        color: '#C92500'
                      }),
                    m: 0
                  }}
                >
                  {value}
                </Box>
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'auto 1fr',
              alignItems: 'center',
              gap: 2,
              mx: 0,
              my: 3
            }}
          >
            <Box
              component="p"
              sx={{
                fontSize: '24px',
                fontWeight: 400,
                lineHeight: '32px',
                letterSpacing: '0.016em',
                color: '#3B3B3F',
                m: 0
              }}
            >
              Shipping info
            </Box>

            <Box
              sx={{
                borderTop: '1px dashed #B3B3B9',
                height: '1px'
              }}
            />
          </Box>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr 1fr',
              gap: 3,
              my: 6
            }}
          >
            {[
              {
                label: 'Shipping name',
                value: lastPayloadCheckout?.shippingAddressCheckout?.fullName || 'Johan Van Ness'
              },
              {
                label: 'Address Line 1',
                value: lastPayloadCheckout?.shippingAddressCheckout?.streetAddress || '912 Uclan Dr'
              },
              { label: 'Address Line 2', value: '' },
              {
                label: 'Address City',
                value: lastPayloadCheckout?.shippingAddressCheckout?.city || 'Houston'
              },
              {
                label: 'Address Country',
                value: lastPayloadCheckout?.shippingAddressCheckout?.state || 'TX'
              },
              {
                label: 'Address postcode',
                value: lastPayloadCheckout?.shippingAddressCheckout?.zipCode || '63124'
              }
            ].map(({ label, value }, i) => (
              <Box key={i}>
                <Box
                  component="p"
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    letterSpacing: '0.016em',
                    color: '#81818B',
                    m: 0
                  }}
                >
                  {label}
                </Box>

                <Box
                  component="p"
                  sx={{
                    fontSize: '18px',
                    fontWeight: 700,
                    lineHeight: '24px',
                    letterSpacing: '0.016em',
                    color: '#3B3B3F',
                    wordBreak: 'break-word',
                    m: 0
                  }}
                >
                  {value}
                </Box>
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'auto 1fr',
              alignItems: 'center',
              gap: 2,
              mx: 0,
              my: 3
            }}
          >
            <Box
              component="p"
              sx={{
                fontSize: '24px',
                fontWeight: 400,
                lineHeight: '32px',
                letterSpacing: '0.016em',
                color: '#3B3B3F',
                m: 0
              }}
            >
              Billing info
            </Box>

            <Box
              sx={{
                borderTop: '1px dashed #B3B3B9',
                height: '1px'
              }}
            />
          </Box>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr 1fr',
              gap: 3,
              my: 6
            }}
          >
            {[
              {
                key: 'billing_name',
                label: 'Billing Name',
                value: lastPayloadCheckout?.billingAddressCheckout?.fullName || 'John Smith'
              },
              {
                key: 'address_line_1',
                label: 'Address line 1',
                value: lastPayloadCheckout?.billingAddressCheckout?.streetAddress || '2000 16th St.'
              },
              {
                key: 'address_line_2',
                label: 'Address line 2',
                value: ''
              },
              {
                key: 'address_city',
                label: 'Address city',
                value: lastPayloadCheckout?.billingAddressCheckout?.city || 'Denver'
              },
              {
                key: 'address_state',
                label: 'Address country',
                value: lastPayloadCheckout?.billingAddressCheckout?.state || 'CO'
              },
              {
                key: 'address_post_code',
                label: 'Address Post code',
                value: lastPayloadCheckout?.billingAddressCheckout?.zipCode || '1410'
              }
            ].map(({ key, label, value }, i) => (
              <Box key={i}>
                <Box
                  component="p"
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    letterSpacing: '0.016em',
                    color: '#81818B',
                    m: 0
                  }}
                >
                  {label}
                </Box>

                <Box
                  component="p"
                  sx={{
                    fontSize: '18px',
                    fontWeight: 700,
                    lineHeight: '24px',
                    letterSpacing: '0.016em',
                    color: '#3B3B3F',
                    wordBreak: 'break-word',
                    ...(['address_line_1'].includes(key) &&
                      ['medium'].includes(riskLevelTII || 'unknown') && {
                        color: '#C92500'
                      }),
                    ...(['address_line_1', 'address_city', 'address_state'].includes(key) &&
                      ['high'].includes(riskLevelTII || 'unknown') && {
                        color: '#C92500'
                      }),
                    m: 0
                  }}
                >
                  {value}
                </Box>
              </Box>
            ))}
          </Box>

          <Popover
            sx={{ pointerEvents: 'none' }}
            PaperProps={{
              sx: {
                bgcolor: 'unset',
                boxShadow: 'unset'
              }
            }}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
            transitionDuration={{ exit: 0 }}
          >
            <Box
              sx={{
                maxWidth: '385px',
                borderRadius: '16px',
                padding: '16px 24px',
                border: '1px solid #DD8608',
                bgcolor: '#FFF'
              }}
            >
              <Box
                component="p"
                sx={{
                  fontSize: '16px',
                  fontWeight: 700,
                  lineHeight: '24px',
                  letterSpacing: '0.016em',
                  color: '#505050',
                  m: 0,
                  mb: 1
                }}
              >
                {getInfo().title}
              </Box>
              {getInfo().bullets ? (
                <>
                  {getInfo().byRisk ? (
                    <>
                      {(getInfo().description[riskLevelTII || 'unknown'] || []).map(
                        (desc: any, i: any) => (
                          <>
                            <Box
                              sx={{
                                display: 'grid',
                                gridTemplateColumns: '24px 1fr',
                                maxWidth: 'fit-content',
                                gap: 1
                              }}
                              key={i}
                            >
                              <Iconify icon="bi:dot" sx={{ color: '#505050' }} />

                              <Box
                                component="p"
                                sx={{
                                  fontSize: '16px',
                                  fontWeight: 400,
                                  lineHeight: '24px',
                                  letterSpacing: '0.016em',
                                  color: '#505050',
                                  margin: 0
                                }}
                              >
                                {desc}
                              </Box>
                            </Box>
                          </>
                        )
                      )}
                    </>
                  ) : (
                    <>
                      {(getInfo().description || []).map((desc: any, i: any) => (
                        <>
                          <Box
                            sx={{
                              display: 'grid',
                              gridTemplateColumns: '24px 1fr',
                              maxWidth: 'fit-content',
                              gap: 1
                            }}
                            key={i}
                          >
                            <Iconify icon="bi:dot" sx={{ color: '#505050' }} />

                            <Box
                              component="p"
                              sx={{
                                fontSize: '16px',
                                fontWeight: 400,
                                lineHeight: '24px',
                                letterSpacing: '0.016em',
                                color: '#505050',
                                margin: 0
                              }}
                            >
                              {desc}
                            </Box>
                          </Box>
                        </>
                      ))}
                    </>
                  )}
                </>
              ) : (
                <Box
                  component="p"
                  sx={{
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    letterSpacing: '0.016em',
                    color: '#505050',
                    margin: 0
                  }}
                >
                  {getInfo().description}
                </Box>
              )}
            </Box>

            <Box sx={{ height: '20px' }} />

            <Box
              sx={{
                position: 'absolute',
                bottom: '10px',
                backgroundColor: '#FFF',
                border: '1px solid #DD8608',
                transform: 'rotate(45deg)',
                left: 'calc(50% - 10px)',
                borderTop: 'unset',
                borderLeft: 'unset',
                width: '20px',
                height: '20px'
              }}
            />
          </Popover>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

interface LogoChartProps {}

function LogoChart(props: LogoChartProps) {
  return (
    <svg width="20" height="20" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.4401 8.53333C14.2614 8.53333 14.9334 9.20533 14.9334 10.0267V13.44C14.9334 14.2613 14.2614 14.9333 13.4401 14.9333C12.6188 14.9333 11.9468 14.2613 11.9468 13.44V10.0267C11.9468 9.20533 12.6188 8.53333 13.4401 8.53333Z"
        fill="#F79E1B"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.46648 0C8.28781 0 8.95981 0.672 8.95981 1.49333V13.44C8.95981 14.2613 8.28781 14.9333 7.46648 14.9333C6.64514 14.9333 5.97314 14.2613 5.97314 13.44V1.49333C5.97314 0.672 6.64514 0 7.46648 0Z"
        fill="#FF5F00"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.49333 4.48H1.70667C2.528 4.48 3.2 5.152 3.2 5.97333V13.44C3.2 14.2613 2.528 14.9333 1.70667 14.9333H1.49333C0.672 14.9333 0 14.2613 0 13.44V5.97333C0 5.152 0.672 4.48 1.49333 4.48Z"
        fill="#EB001B"
      />
    </svg>
  );
}
